<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>选择资源</span>
          <div
            class="create-action cursor-pointer"
            v-if="!$utils.isMobile().mobile"
            @click.stop.prevent="createShow = true"
          >
            <span>添加</span>
          </div>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div
            class="list-item cursor-pointer"
            v-for="(i, k) in list"
            :key="k"
            @click.stop.prevent="itemClick(k)"
          >
            <div class="item-close" @click.stop.prevent="itemDelete(i)"></div>
            <div :class="`item-img type-${i.ext}`">
              <img
                :src="
                  i.ext == 'pdf'
                    ? $ossRes('/manage/icon_pdf.png')
                    : i.preview_img
                "
              />
            </div>
            <div
              :class="`item-choose ${k == currentChoose ? 'active' : ''}`"
            ></div>
            <div class="down">
              <p class="item-title">{{ decodeURIComponent(i.name) }}</p>
            </div>
          </div>
          <list-empty
            :show="list.length == 0"
            tab="暂无资源"
            :full="true"
          ></list-empty>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>取消</span>
        </div>
        <div
          class="action-item cursor-pointer active"
          @click.stop.prevent="submit"
        >
          <span>确认</span>
        </div>
      </div>
    </div>
    <pop-confirm
      :show="deleteShow"
      :tips="'是否确认删除⽂件？'"
      @submit="deleteCallback"
      @close="deleteClose"
    ></pop-confirm>
    <upload-confirm
      :show="createShow"
      @close="createClose"
      @success="getList"
    ></upload-confirm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import listEmpty from "@/components/common/ListEmpty.vue";
import UploadConfirm from "@/components/upload/UploadConfirm.vue";
import PopConfirm from "@/components/PopConfirm.vue";
export default {
  components: { listEmpty, UploadConfirm, PopConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createShow: false,
      deleteShow: false,
      deleteCallback: () => {},
      styleIndex: {},
      list: [],
      currentScreenIndex: -1,
      currentPage: 1,
      perPage: 999,
      currentChoose: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
          this.getList();
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          this.list = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    deleteClose() {
      this.deleteShow = false;
    },
    itemDelete(i) {
      this.deleteShow = true;
      this.deleteCallback = async () => {
        this.deleteClose();
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "操作中...",
        });
        const result = await this.$http.post("/resource/del", {
          id: i.id,
        });
        if (result.code == 200) {
          this.$toast.success("操作成功");
          this.getList(true);
        } else {
          this.$toast.fail("操作失败：" + result.msg);
        }
      };
    },
    createClose() {
      this.createShow = false;
    },
    itemClick(k) {
      this.currentChoose = k;
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    async getList(noToast) {
      if (!noToast) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
      }
      const result = await this.$http.get("/resource/list", {
        page: this.currentPage,
        per_page: this.perPage,
      });
      if (result.code == 200) {
        this.$toast.clear();
        if (result.data.data && result.data.data.length > 0) {
          if (this.currentPage === 1) {
            this.list = [...result.data.data];
          } else {
            this.list = [...this.list, ...result.data.data];
          }
        } else {
          this.list = [];
        }
      } else {
        this.$toast.fail("加载失败：" + result.msg);
      }
    },
    close() {
      this.$emit("close");
    },
    async submit() {
      const item = this.list[this.currentChoose];
      if (["pdf"].includes(item.ext)) {
        const result = await this.$http.get("/resource/pdf-img", {
          resource_id: item.id,
        });
        if (result.code == 200) {
          if (result.data && result.data.length > 0) {
            item["pdf2imgs"] = result.data;
          } else {
            this.$toast.fail("文件仍在处理中，请稍后再试~");
            return;
          }
        } else {
          this.$toast.fail(result.msg);
          return;
        }
        this.$emit("submit", item);
      } else {
        if (["mp4", "mov"].includes(item.ext) && this.$utils.isMobile().ios) {
          this.$toast.fail("请在电脑投屏视频");
          return;
        }
        this.$emit("submit", item);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/screen_projection/resource/resource_bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 2500px;
    height: 1474px;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 126px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      box-sizing: border-box;
      padding: 0 40px 0 60px;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 54px;
          height: 52px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/icon_resource.png);
          background-size: 100% 100%;
          margin-right: 24px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .create-action {
          margin-left: 40px;
          width: 202px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(38, 228, 252, 1);
          border: 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 202px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -101px;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(2, 2, 2, 1);
          }
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      max-height: 1130px;
      overflow-x: hidden;
      overflow-y: auto;
      align-items: center;
      box-sizing: border-box;
      padding: 80px 90px 0 90px;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .list {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        .list-item {
          width: 520px;
          height: 370px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          margin-bottom: 84px;
          margin-right: 52px;
          position: relative;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .item-choose {
            position: absolute;
            left: 16px;
            top: 16px;
            width: 56px;
            height: 56px;
            background: rgba(255, 255, 255, 0.8);
            box-sizing: border-box;
            border: 2px solid #ffffff;
            border-radius: 50%;
            z-index: 1;
            &.active {
              background: rgba(37, 228, 251, 1);
              border: 0;
              &::after {
                content: "";
                position: absolute;
                width: 49px;
                height: 37.5px;
                top: 50%;
                left: 50%;
                margin-top: -18.75px;
                margin-left: -22.5px;
                background: url(#{$PublicEnv}/pop/screen_projection/resource/resource_choose.png);
                background-size: 100% 100%;
                z-index: 2;
              }
            }
          }
          .item-close {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 52px;
            height: 52px;
            background: url(#{$PublicEnv}/manage/icon_close.png);
            background-size: 100% 100%;
            z-index: 1;
          }
          .item-img {
            flex-shrink: 0;
            width: 100%;
            height: 295px;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(246, 246, 246, 1);
            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
            }
            &.type-mp4 {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(246, 246, 246, 1);
              img {
                width: auto;
                height: auto;
                min-height: auto;
                max-width: 100%;
                max-height: 100%;
              }
              &::after {
                content: "";
                width: 76px;
                position: absolute;
                height: 76px;
                left: 50%;
                margin-left: -38px;
                top: 50%;
                margin-top: -38px;
                background: url(#{$PublicEnv}/manage/icon_video.png);
                background-size: 100% 100%;
              }
            }
            &.type-pdf {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(246, 246, 246, 1);
              img {
                width: 200px;
                min-height: auto;
                height: auto;
              }
            }
          }
          .down {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 36px;
            position: relative;
            background: #fff;
            .activity-nav {
              width: 320px;
              height: 246px;
              border-radius: 22px;
              background: linear-gradient(
                45deg,
                rgba(31, 39, 53, 1),
                rgba(51, 58, 72, 1)
              );
              visibility: hidden;
              opacity: 0;
              transition: all 0.4s ease-in-out;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 48px 0;
              z-index: 10;
              &.active {
                visibility: visible;
                top: -60px;
                opacity: 1;
              }
              .nav-item {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: all 0.3s ease-in-out;
                &:first-child {
                  background: linear-gradient(
                    90deg,
                    rgba(68, 86, 176, 1),
                    rgba(36, 60, 115, 0) 80%
                  );
                }
                height: 64px;
                box-sizing: border-box;
                box-sizing: border-box;
                padding: 22px 54px 12px 54px;
                span {
                  margin-left: 14px;
                  font-size: 30px;
                  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }
                &.non-active {
                  // span {
                  //   color: #999999;
                  // }
                }
                .icon-edit {
                  flex-shrink: 0;
                  width: 56px;
                  height: 56px;
                  background: url(#{$PublicEnv}/activity_my/icon_edit.png);
                  background-size: 100% 100%;
                }
                .icon-over {
                  flex-shrink: 0;
                  width: 56px;
                  height: 56px;
                  background: url(#{$PublicEnv}/activity_my/icon_over.png);
                  background-size: 100% 100%;
                }
              }
            }
            .item-title {
              font-size: 28px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .action-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .action-item {
        width: 292px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 80px;
        border-radius: 50px;
        box-sizing: border-box;
        background: rgba(37, 229, 252, 0.2);
        &:last-child {
          margin-right: 0;
        }
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 500;
          color: rgba(37, 228, 251, 1);
        }
        &.active {
          background: rgba(38, 228, 252, 1);
          border: 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 340px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -170px;
          }
          span {
            color: rgba(2, 2, 2, 1);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
