var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{class:`bg ${_vm.isPackUp ? 'is-pack-up' : ''}`,style:(_vm.styleIndex)},[_c('div',{staticClass:"container animation-big box-shadow"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPackUp),expression:"!isPackUp"}],staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"icon-tips"}),_c('span',[_vm._v(_vm._s(_vm.videoChatUserList.length)+"人视频聊天中")])]),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"right"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.destroy.apply(null, arguments)}}},[_vm._v("退出")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.packUpOpen.apply(null, arguments)}}},[_c('span',[_vm._v("收起")]),_c('div',{staticClass:"icon-pack-up"})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPackUp),expression:"!isPackUp"}],staticClass:"content"},[_c('div',{staticClass:"action-wrapper"},[_c('div',{class:`action-item cursor-pointer ${_vm.audio_on ? 'active' : ''}`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.audioChange.apply(null, arguments)}}},[_c('div',{staticClass:"action-audio"})]),_c('div',{class:`action-item cursor-pointer ${_vm.video_on ? 'active' : ''}`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
              _vm.videoChange();
            }).apply(null, arguments)}}},[_c('div',{staticClass:"action-video"})]),_c('div',{class:`action-item cursor-pointer ${
            _vm.front_or_back ? 'active' : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.frontBackChange.apply(null, arguments)}}},[_c('div',{staticClass:"action-front-back"})]),_c('div',{class:`action-item cursor-pointer ${
            _vm.audio_receiver_on ? 'active' : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.audioReceiverChange.apply(null, arguments)}}},[_c('div',{staticClass:"action-audio-receiver"})])]),_c('div',{staticClass:"view-wrapper"},[_c('div',{staticClass:"view-list"},_vm._l((_vm.videoChatUserList),function(i,k){return _c('div',{key:k,staticClass:"view-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.itemClick(i, k)}}},[(i.user_tag)?_c('div',{staticClass:"user-tag user-tag-auto"},[_c('span',[_vm._v(_vm._s(i.user_tag))])]):_vm._e(),(_vm.checkRtcConnect(i))?_c('div',{staticClass:"user-audioing"},[(
                  (i.web_data && JSON.parse(i.web_data).is_audioing) ||
                  (_vm.userInfo.user_id == i.user_id && _vm.audio_on)
                )?_c('div',{staticClass:"icon-audio"}):_vm._e(),_c('span',[_vm._v(_vm._s(i.user_name))])]):_vm._e(),(!_vm.checkRtcConnect(i))?_c('div',{staticClass:"user-audioing"},[_c('span',[_vm._v(_vm._s(i.user_name)+"正在连接中...")])]):_vm._e(),_c('div',{staticClass:"view-pic"},[(i.small_img)?_c('img',{attrs:{"src":i.small_img}}):(i.web_data && JSON.parse(i.web_data).small_img)?_c('img',{attrs:{"src":JSON.parse(i.web_data).small_img}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  (i.user_id == _vm.userInfo.user_id && _vm.video_on) ||
                  i.user_id != _vm.userInfo.user_id
                ),expression:"\n                  (i.user_id == userInfo.user_id && video_on) ||\n                  i.user_id != userInfo.user_id\n                "}],class:`view-video ${
                  _vm.currentShowViewVideo == `view_${i.user_id}`
                    ? 'view-video-show'
                    : ''
                }`,attrs:{"id":`view_${i.user_id}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showViewVideo(`view_${i.user_id}`)}}})]),(_vm.isOwner && i.user_id != _vm.userInfo.user_id)?_c('div',{staticClass:"close"},[_c('div',{staticClass:"icon-close"})]):_vm._e()])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPackUp),expression:"isPackUp"}],staticClass:"pack-up-content"},[(_vm.userInfo.user_tag)?_c('div',{staticClass:"user-tag user-tag-auto"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.user_tag))])]):_vm._e(),(_vm.audio_on)?_c('div',{staticClass:"user-audioing"},[_c('div',{staticClass:"icon-audio"}),_c('span',[_vm._v(_vm._s(_vm.userInfo.user_name))])]):_vm._e(),_c('div',{staticClass:"content-bottom"},[_c('span',[_vm._v(_vm._s(_vm.videoChatUserList.length)+"人视频聊天中")]),_c('div',{staticClass:"lookup cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.packUpClose.apply(null, arguments)}}},[_c('span',[_vm._v("查看")])])]),_c('div',{staticClass:"view-pic"},[_c('img',{attrs:{"src":_vm.userInfo.small_img}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_on),expression:"video_on"}],staticClass:"view-video",attrs:{"id":`view_mine`}})])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }