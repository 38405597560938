<template>
  <action-confirm :show="show" @close="createClose" @submit="createCallback">
    <div class="action-confirm-tips" v-if="isUploaded">
      <div class="icon-upload-success"></div>
      <span>上传完成</span>
      <span class="tips">{{ fileNameShow(createUploadUrl) }}</span>
    </div>
    <div class="action-confirm-tips" v-else>
      <van-uploader
        :after-read="afterRead"
        accept=".jpg,.jpeg,.png,.pdf,.mp4,.mov"
      >
        <div class="action-confirm-tips cursor-pointer">
          <div class="icon-upload"></div>
          <span>点击上传</span>
          <p class="tips">（⽀持100M以内的MP4、MOV、JPG、JPEG、PNG、PDF）</p>
        </div>
      </van-uploader>
    </div>
  </action-confirm>
</template>
<script>
import ActionConfirm from "@/components/common/ActionConfirm.vue";
export default {
  components: { ActionConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    {
      return {
        isUploaded: false,
        maxSize: 104857600,
        chunkSize: 5242880,
        createUploadFile: "",
        createUploadFileType: "",
        createUploadUrl: "",
        createUploadPreviewImage: "",
      };
    }
  },
  created() {},
  mounted() {},
  methods: {
    async afterRead(f) {
      const file = f.file;
      if (file.size > this.maxSize) {
        this.$toast.fail("请上传100M以内的文件");
        return;
      }
      this.createUploadFileType = this.getFileType(file.type);
      if (!this.createUploadFileType) {
        this.$toast.fail("仅⽀持100M以内的MP4、MOV、JPG、JPEG、PNG、PDF");
        return;
      }
      this.createUploadFile = file;
      if (file.size <= this.chunkSize) {
        // 文件小于10MB，直接上传
        this.uploadSingle();
      } else {
        // 文件大于10MB，切片上传
        this.uploadChunk();
      }
    },
    getPreviewImage() {
      if (
        this.createUploadFileType == "mp4" ||
        this.createUploadFileType == "mov"
      ) {
        this.$utils
          .getFirstFrameByVideo(this.createUploadUrl)
          .then(async (obj) => {
            // this.createUploadPreviewImage = obj.poster;
            const form = new FormData();
            form.append(
              "file_name",
              this.$utils.base64ToFile(
                obj.poster,
                `cover_${this.createUploadFile.name}.png`
              )
            );
            const result = await this.$http.uploads(
              `/common/ali-cloud/upload`,
              form
            );
            if (result.data.code === 200 && result.data.data) {
              this.createUploadPreviewImage = result.data.data.img_url;
            } else {
              this.$toast.fail("获取缩略图失败：" + result.data?.msg);
            }
          });
      } else {
        this.createUploadPreviewImage = this.createUploadUrl;
      }
    },
    async uploadChunk() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "上传中...",
      });
      const file = this.createUploadFile;
      const { name, size } = this.createUploadFile;
      const result = await this.$http.post(`/common/ali-cloud/upload-init`, {
        part_num: Math.ceil(size / this.chunkSize),
        file_name: name,
        file_size: size,
      });
      let uploadId = "";
      if (result.code === 200) {
        uploadId = result.data.upload_id;
      } else {
        this.$toast.fail("上传初始化失败：" + result.msg);
      }
      let uploadSize = 0;
      while (uploadSize < size) {
        this.$toast.clear();
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: `上传中(${Math.ceil((uploadSize / size) * 100)}%)`,
        });
        const fileChunk = file.slice(uploadSize, uploadSize + this.chunkSize);
        const form = new FormData();
        const partNo = uploadSize / this.chunkSize + 1;
        form.append("file", fileChunk);
        form.append("part_no", partNo);
        form.append("upload_id", uploadId);
        const res = await this.$http.uploads(
          `/common/ali-cloud/upload-part`,
          form
        );
        if (res.data.code === 200) {
          uploadSize += fileChunk.size;
          if (uploadSize == size) {
            this.$toast.clear();
            this.createUploadUrl = res.data.data.url;
            this.isUploaded = true;
            this.getPreviewImage();
            // this.pdfAnalysis();
          }
        } else {
          this.$toast.clear();
          this.$toast.fail("上传失败：" + res.data?.msg);
          await this.$http.post(`/common/ali-cloud/cancel-upload`, {
            upload_id: uploadId,
          });
          return;
        }
      }
    },
    async uploadSingle() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "上传中...",
      });
      const form = new FormData();
      form.append("file_name", this.createUploadFile);
      const result = await this.$http.uploads(`/common/ali-cloud/upload`, form);
      if (result.data.code === 200 && result.data.data) {
        this.$toast.clear();
        this.createUploadUrl = result.data.data.img_url;
        this.isUploaded = true;
        this.getPreviewImage();
        // this.pdfAnalysis();
      } else {
        this.$toast.fail("上传失败：" + result.data?.msg);
      }
    },
    async pdfAnalysis() {
      if (["pdf"].includes(this.createUploadFileType)) {
        this.$toast.loading({
          message: "文件解析中...",
          duration: 0,
          forbidClick: false,
        });
        const timeStart = Date.now();
        let error = false;
        try {
          await this.$utils.getCanvasStreamFromPDF({
            url: this.createUploadUrl,
            check: true,
          });
        } catch (e) {
          error = true;
        }
        const timeEnd = Date.now();
        if (timeEnd - timeStart > 10000 || error) {
          this.$toast.fail("文档解析错误，请重新上传");
          this.createUploadFile = "";
          this.createUploadFileType = "";
          this.createUploadUrl = "";
          this.createUploadPreviewImage = "";
          this.isUploaded = false;
        } else {
          this.$toast.success("文档解析成功");
        }
      }
    },
    getFileType(v) {
      let type = "";
      if (v.indexOf("jpeg") > -1 || v.indexOf("jpg") > -1) {
        type = "jpg";
      } else if (v.indexOf("png") > -1) {
        type = "png";
      } else if (v.indexOf("pdf") > -1) {
        type = "pdf";
      } else if (v.indexOf("mp4") > -1) {
        type = "mp4";
      } else if (v.indexOf("quicktime") > -1) {
        type = "mov";
      }
      return type;
    },
    fileNameShow(v) {
      v = v.substring(v.lastIndexOf("/") + 1);
      v = v.substring(v.lastIndexOf("-") + 1);
      return decodeURIComponent(v);
    },
    createClose() {
      this.isUploaded = false;
      this.createShow = false;
      this.createUploadUrl = "";
      this.createUploadFile = {};
      this.$emit("close");
    },
    async createCallback() {
      if (
        !this.createUploadUrl ||
        !this.createUploadFile ||
        !this.createUploadFileType
      ) {
        this.$toast.fail("请先上传资源");
        return;
      }
      this.$toast.loading({
        duration: 3000, // 持续展示 toast
        forbidClick: true,
        message: "操作中...",
      });
      const result = await this.$http.post("/resource/create", {
        url: this.createUploadUrl,
        file_size: this.createUploadFile.size,
        preview_img: this.createUploadPreviewImage,
      });
      if (result.code == 200) {
        this.createClose();
        this.$toast.success("添加成功");
        this.createUploadFile = "";
        this.createUploadFileType = "";
        this.createUploadUrl = "";
        this.createUploadPreviewImage = "";
        this.isUploaded = false;
        this.$emit("success");
      } else {
        result.code != 504 && this.$toast.fail(result.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-upload {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload.png);
    background-size: 100% 100%;
  }
  .icon-upload-success {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload_success.png);
    background-size: 100% 100%;
    margin-bottom: 6px;
  }
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
  p {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    padding: 0 60px;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
@media (orientation: portrait) {
  .action-confirm-tips {
    .icon-upload {
      width: 420px;
      height: 420px;
    }
    .icon-upload-success {
      width: 420px;
      height: 420px;
      margin-bottom: 77px;
    }
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
    p {
      font-size: 184px;
      padding: 0 180px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
