<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div
      class="container animation-big box-shadow"
      @click.stop.prevent="() => {}"
    >
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>广播内容</span>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="textarea-wrapper">
          <textarea
            v-model="content"
            placeholder="请输入内容..."
            maxlength="150"
          ></textarea>
        </div>
        <div class="bottom-wrapper">
          <span>{{ content.length }}/150</span>
          <div class="action-wrapper">
            <span>您的广播内容将全场可见</span>
            <div
              class="action-item cursor-pointer"
              @click.stop.prevent="submit"
            >
              <span>确定</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      currentScreenIndex: -1,
      content: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    submit() {
      this.$emit("send", {
        cmd_type: "announcement_input",
        cmd_info: {
          text: this.content,
        },
      });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: rgba(36, 36, 36, 0.95);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 1076px;
    height: 810px;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 58px;
          height: 56px;
          background: url(#{$PublicEnv}/pop/announcement/sound.png);
          background-size: 100% 100%;
          margin-right: 24px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        padding: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 36px;
          height: 36px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 46px 60px;
      .textarea-wrapper {
        flex-shrink: 0;
        width: 956px;
        height: 490px;
        background: rgba(36, 40, 49, 0.4);
        border-radius: 48px;
        box-sizing: border-box;
        overflow: hidden;
        textarea {
          padding: 30px 44px;
          width: 100%;
          height: 100%;
          box-sizing: border;
          background: transparent;
          outline: none;
          border: 0;
          color: rgba(255, 255, 255, 1);
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
        }
      }
      .bottom-wrapper {
        margin-top: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        span {
          font-size: 32px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #b4b4b4;
        }
        .action-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 32px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #b4b4b4;
          }
          .action-item {
            margin-left: 30px;
            width: 198px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            box-sizing: border-box;
            background: rgba(40, 200, 188, 1);
            span {
              font-size: 36px;
              font-family: Source Han Sans CN-Bold, Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
