<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div
      class="container animation-big box-shadow"
      @click.stop.prevent="() => {}"
    >
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>正在分享内容</span>
        </div>
        <div class="right"></div>
      </div>
      <div class="content">
        <div class="screen-list">
          <div
            :class="`screen-item ${currentScreenIndex == k ? 'active' : ''}`"
            v-for="(i, k) in agoraLiveMoreScreenBindList"
            :key="k"
            @click.stop.prevent="screenClick(k)"
          >
            <div class="view">
              <div
                :class="`video ${i.isCamera && !isFrontOrBack ? 'camera' : ''}`"
                :id="`screen_video_${i.uid}`"
              ></div>
              <div
                class="close cursor-pointer"
                @click.stop.prevent="screenClose(i)"
              >
                <div class="icon"></div>
              </div>
              <div
                class="front cursor-pointer"
                v-if="i.isCamera"
                @click.stop.prevent="frontOrBack(i)"
              >
                <div class="icon"></div>
              </div>
            </div>
            <div class="name">
              <span>{{ i.screen_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>关闭</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      currentScreenIndex: -1,
      isFrontOrBack: 0,
      list: [],
      liveList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
    ...mapGetters(["agoraLiveUserPublishedListenerMoreScreenBindList"]),
  },
  mounted() {},
  watch: {
    "$store.state.agoraLiveMoreScreenBindList": {
      handler: function () {
        console.log("$store.state.agoraLiveMoreScreenBindList", "change");
        // const _this = this;
        this.show && this.reload();
        // _this.reset();
        // setTimeout(() => {
        //   _this.init();
        // }, 1000);
      },
    },
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
          _this.init();
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          _this.reset();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    reload() {
      this.$emit("reload");
    },
    async screenClose({ screen_u3d }) {
      this.$emit("screenclose", {
        screen_u3d,
        isMe: 1,
      });
    },
    reset() {
      const _this = this;
      _this.list = [];
      _this.liveList = [];
      _this.agoraLiveMoreScreenBindList.map((i, k) => {
        // const elementId = `screen_video_${i.uid}`;
        const liveObject = _this.agoraLiveMoreList[k];
        const videoTrack = liveObject.localVideoTrack;
        if (videoTrack) {
          try {
            videoTrack.stop();
          } catch (e) {
            //
          }
        }
      });
    },
    init() {
      const _this = this;
      _this.list = _this.agoraLiveMoreScreenBindList;
      _this.liveList = _this.agoraLiveMoreList;
      setTimeout(() => {
        _this.$nextTick(() => {
          _this.list.map((i, k) => {
            let liveObject = {};
            // if (i.liveIndex == -1) {
            //   liveObject = _this.agoraLive;
            // } else {
            liveObject = _this.liveList[k];
            // }
            let videoTrack = liveObject.localVideoTrack;
            // if (i.isFile) {
            //   videoTrack =
            //     _this.agoraLiveUserPublishedListenerMoreScreenBindList[k]
            //       .videoTrack;
            // }
            if (videoTrack) {
              // web推流
              videoTrack.play(`screen_video_${i.uid}`);
            }
          });
          console.log(_this.agoraLive, "_this.agoraLive");
        });
      }, 300);
    },
    async frontOrBack({ screen_u3d }) {
      const _this = this;
      this.$emit("frontorback", {
        screen_u3d,
        value: !this.isFrontOrBack,
        callback: () => {
          _this.reset();
          _this.init();
          this.isFrontOrBack = !this.isFrontOrBack;
        },
      });
    },
    screenClick(k) {
      this.currentScreenIndex = k;
      this.close();
      this.$emit("screenOpen", {
        screen_u3d:
          this.agoraLiveMoreScreenBindList[this.currentScreenIndex].screen_u3d,
        agora_info: {
          uid: this.agoraLiveMoreScreenBindList[this.currentScreenIndex].uid,
        },
      });
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: rgba(36, 36, 36, 0.95);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 2120px;
    height: 1360px;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 158px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 54px;
          height: 52px;
          background: url(#{$PublicEnv}/pop/screen_projection/share.png);
          background-size: 100% 100%;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        padding: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 36px;
          height: 36px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 140px 160px 80px 160px;
      .screen-list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .screen-item {
          width: 560px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 60px;
          margin-bottom: 60px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .view {
            width: 100%;
            height: 316px;
            position: relative;
            box-sizing: border-box;
            border-radius: 18px;
            overflow: hidden;
            .close {
              position: absolute;
              right: 12px;
              top: 12px;
              width: 48px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 6px;
              .icon {
                width: 26px;
                height: 26px;
                background: url(#{$PublicEnv}/pop/screen_projection/close.png);
                background-size: 100% 100%;
              }
            }
            .video {
              width: 100%;
              height: 100%;
              &.camera {
                transform: rotateY(180deg);
              }
            }
            .front {
              position: absolute;
              left: 12px;
              top: 12px;
              width: 48px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 6px;
              .icon {
                width: 26px;
                height: 26px;
                background: url(#{$PublicEnv}/pop/screen_projection/front.png);
                background-size: 100% 100%;
              }
            }
          }
          .name {
            width: 100%;
            margin-top: 32px;
            text-align: center;
            span {
              font-size: 32px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #cccccc;
            }
          }
          &.active {
            .view {
              border: 6px solid rgba(41, 198, 184, 1);
            }
            .name {
              span {
                border: rgba(41, 221, 205, 1);
              }
            }
          }
        }
      }
    }
    .action-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .action-item {
        width: 368px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        border-radius: 50px;
        box-sizing: border-box;
        border: 3px solid rgba(41, 221, 205, 1);
        &:last-child {
          margin-right: 0;
        }
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
        &.active {
          background: rgba(40, 200, 188, 1);
          border: 0;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
