<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div :class="`total ${$utils.isMobile().wechat ? 'wechat-mini' : ''}`">
      <!-- <span class="title">用户设置</span> -->
      <div class="content">
        <div class="left">
          <div class="label-wrapper">
            <span>用户设置</span>
            <span>USER SETTINGS</span>
          </div>
          <div class="sex-wrapper">
            <div
              :class="`sex-item cursor-pointer ${
                sexCurrentIndex == 0 ? 'active' : ''
              }`"
              @click.stop.prevent="selectSex(0)"
            >
              <div class="icon boy"></div>
              <span>男士</span>
            </div>
            <div
              :class="`sex-item cursor-pointer ${
                sexCurrentIndex == 1 ? 'active' : ''
              }`"
              @click.stop.prevent="selectSex(1)"
            >
              <div class="icon girl"></div>
              <span>女士</span>
            </div>
          </div>
          <div class="role-list">
            <div
              :class="`role-item cursor-pointer ${
                roleCurrentIndex == k ? 'active' : ''
              }`"
              v-for="(i, k) in roleList[sexCurrentIndex]"
              :key="k"
              @click.stop.prevent="selectRole(k)"
            >
              <img :src="i.small_img" />
            </div>
          </div>
        </div>
        <div class="middle">
          <img
            v-if="roleList && roleList.length > 0 && roleList[sexCurrentIndex]"
            :src="roleList[sexCurrentIndex][roleCurrentIndex].big_img"
          />
          <div class="role-bottom"></div>
        </div>
        <div class="right">
          <span class="right-label" v-if="userInfo.user_tag">{{
            userInfo.user_tag
          }}</span>
          <div class="user-avatar-box" v-if="userInfo && userInfo.avatar">
            <div
              class="user-avatar cursor-pointer"
              @click.stop.prevent="showCard"
            >
              <img :src="userInfo.avatar" />
            </div>
          </div>
          <div
            class="name-wrapper cursor-pointer"
            @click.stop.prevent="showCard"
          >
            <div class="name-icon"></div>
            <span>{{ userInfo.user_name }}</span>
            <!-- <input v-model="name" placeholder="请输入名字" /> -->
          </div>
          <!-- <div
            class="card-wrapper cursor-pointer"
            @click.stop.prevent="showCard"
            v-if="!fromme"
          >
            <span>修改名片</span>
          </div> -->
          <div class="login-action cursor-pointer" @click.stop.prevent="submit">
            <span>进入活动</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fromme: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sexCurrentIndex: 0,
      roleList: [],
      roleCurrentIndex: 0,
      name: "",
      styleIndex: {},
    };
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.name = this.userInfo.user_name || "";
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    ...mapGetters(["config"]),
    ...mapGetters(["userInfo"]),
  },
  methods: {
    showCard() {
      this.$emit("card");
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    submit() {
      if (this.fromme) {
        this.close();
        return;
      }
      this.$http
        .post("/user/save", {
          user_id: this.userInfo.id,
          role_id:
            this.roleList[this.sexCurrentIndex][this.roleCurrentIndex].id,
          // nickname: this.userInfo.user_name,
          sex: this.sexCurrentIndex + 1,
        })
        .then((res) => {
          if (res.code == 200) {
            const userInfo = this.userInfo;
            userInfo["role_id"] =
              this.roleList[this.sexCurrentIndex][this.roleCurrentIndex].id;
            // userInfo["avatar"] =
            //   this.roleList[this.sexCurrentIndex][
            //     this.roleCurrentIndex
            //   ].small_img;
            userInfo["small_img"] =
              this.roleList[this.sexCurrentIndex][
                this.roleCurrentIndex
              ].small_img;
            // userInfo["user_name"] = this.name;
            this.$store.dispatch("saveUserInfo", userInfo);
            this.$emit("submit");
          } else {
            this.$toast.fail(res.msg);
          }
        });
    },
    selectRole(type) {
      if (this.fromme) {
        return;
      }
      this.roleCurrentIndex = type;
    },
    selectSex(type) {
      if (this.fromme) {
        return;
      }
      this.sexCurrentIndex = type;
    },
    getInfo() {
      this.$http.get("/user/getRole").then((res) => {
        if (res.code == 200) {
          this.roleList = [[...res.data.man], [...res.data.woman]];
          if (this.userInfo.role_id > 0) {
            // 参加过活动，则选取上一次选择的
            this.roleList.map((i, k) => {
              i.map((m, n) => {
                if (m.id == this.userInfo.role_id) {
                  this.roleCurrentIndex = n;
                  this.sexCurrentIndex = k;
                }
              });
            });
          } else {
            // 未参加过活动，则随机
            const list = [...res.data.man, ...res.data.woman];
            const randomIndex = Math.floor(Math.random() * list.length + 1);
            this.sexCurrentIndex = 0;
            if (randomIndex > res.data.man.length) {
              this.sexCurrentIndex = 1;
              this.roleCurrentIndex = randomIndex - res.data.man.length - 1;
            } else {
              this.roleCurrentIndex = randomIndex - 1;
            }
          }
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(#{$PublicEnv}/pop/role_select/v2/role_select_bg.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .total {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.wechat-mini {
      transform: scale(0.88);
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 72px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
    .content {
      margin-top: 14px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      .left {
        flex-shrink: 0;
        margin-top: 270px;
        margin-right: 200px;
        width: 958px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        z-index: 2;
        .label-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 72px;
          span {
            &:first-child {
              font-size: 72px;
              font-family: OPPOSans-Bold, OPPOSans;
              font-weight: bold;
              color: #ffffff;
              padding-left: 68px;
            }
            &:nth-child(2) {
              margin-top: 20px;
              font-size: 88px;
              font-family: OPPOSans-Bold, OPPOSans;
              font-weight: bold;
              line-height: 103px;
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(
                92deg,
                #9966ff 0%,
                #3b70c9 56%,
                #20e9ff 99%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .sex-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .sex-item {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-right: 156px;
            position: relative;
            &:last-child {
              margin-right: 0;
            }
            .icon {
              margin-right: 36px;
              &.boy {
                width: 48px;
                height: 48px;
                background: url(#{$PublicEnv}/pop/role_select/icon_boy.png);
                background-size: 100% 100%;
              }
              &.girl {
                width: 32px;
                height: 52px;
                background: url(#{$PublicEnv}/pop/role_select/icon_girl.png);
                background-size: 100% 100%;
              }
            }
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #939393;
            }
            &.active {
              .icon {
                &.boy {
                  background: url(#{$PublicEnv}/pop/role_select/icon_boy_active.png);
                  background-size: 100% 100%;
                }
                &.girl {
                  background: url(#{$PublicEnv}/pop/role_select/icon_girl_active.png);
                  background-size: 100% 100%;
                }
              }
              span {
                position: relative;
                &::after {
                  position: absolute;
                  content: "";
                  bottom: -14px;
                  height: 6px;
                  width: 100%;
                  left: 0;
                  right: 0;
                  background: rgba(41, 221, 205, 1);
                  box-shadow: 0 0 20px 0px rgba(41, 221, 205, 1);
                }
              }
            }
          }
        }
        .role-list {
          margin-top: 72px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 1040px;
          .role-item {
            width: 260px;
            height: 260px;
            border-radius: 40px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            &:last-child {
              margin-right: 0;
            }
            img {
              width: 200px;
              height: 200px;
            }
            &.active {
              width: 260px;
              height: 260px;
              background: url(#{$PublicEnv}/pop/role_select/v2/role_bg.png);
              background-size: 100% 100%;
              padding: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .middle {
        flex: 1;
        position: relative;
        img {
          width: 700px;
          z-index: 1;
          position: relative;
        }
        .role-bottom {
          background: url(#{$PublicEnv}/pop/role_select/v2/role_bottom.png);
          background-size: 100% 100%;
          width: 1426px;
          height: 316px;
          position: absolute;
          bottom: -80px;
          left: 50%;
          margin-left: -713px;
        }
        .login-action {
          width: 640px;
          height: 120px;
          background: rgba(40, 200, 188, 1);
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          span {
            font-size: 52px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 4px;
          }
        }
      }
      .right {
        width: 1309px;
        height: 1300px;
        background: url(#{$PublicEnv}/pop/role_select/v2/role_select_info_bg.png);
        background-size: 100% 100%;
        flex-shrink: 0;
        margin-top: 150px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 240px 195.5px 225px 285.5px;
        box-sizing: border-box;
        .right-label {
          font-size: 56px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 20px;
        }
        .user-avatar-box {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          margin-bottom: 50px;
          box-sizing: border-box;
          padding: 10px;
          border: 1px solid rgba(255, 255, 255, 0.38);
          overflow: hidden;
          .user-avatar {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
        .name-wrapper {
          width: 534px;
          height: 136px;
          border-radius: 30px 30px 30px 30px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.38);
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 36px 46px;
          .name-icon {
            width: 64px;
            height: 64px;
            background: url(#{$PublicEnv}/pop/role_select/icon_name.png);
            background-size: 100% 100%;
            margin-right: 30px;
            flex-shrink: 0;
          }
          input,
          span {
            font-size: 44px;
            font-family: Source Han Sans CN-Light, Source Han Sans CN;
            font-weight: 300;
            color: #ffffff;
            background: none;
            outline: none;
            border: 0;
            flex: 1;
          }
        }
        .card-wrapper {
          width: 534px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(38, 227, 251, 0.2);
          border: 2px solid rgba(37, 228, 251, 1);
          border-radius: 80px;
          margin-top: 66px;
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
        }
        .login-action {
          width: 534px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(38, 228, 252, 1);
          border-radius: 80px;
          margin-top: 34px;
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(2, 2, 2, 1);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .total {
      transform: scale(1.8) !important;
    }
  }
}
</style>
