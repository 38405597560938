<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big box-shadow">
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <div class="left">
        <div class="avatar">
          <img :src="from_user.avatar" />
        </div>
        <div class="name-tips">
          <span class="name">{{ from_user.user_name }}</span>
          <span class="tips">邀请你加入视频聊天</span>
        </div>
      </div>
      <div class="right">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>取消</span>
        </div>
        <div
          class="action-item active cursor-pointer"
          @click.stop.prevent="submit"
        >
          <span>确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    from_user: {},
  },
  data() {
    return {
      styleIndex: {},
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      // this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    submit() {
      this.$emit("submit");
    },
    close() {
      this.$emit("close", this.from_user);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 102;
  top: 60px;
  width: 1340px;
  height: 248px;
  left: 50%;
  margin-left: -670px;
  .container {
    width: 100%;
    height: 100%;
    background: rgba(36, 36, 36, 0.95);
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 46px 62px;
    .close {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 28px;
      right: 28px;
      background: url(#{$PublicEnv}/pop/user_list/close.png);
      background-size: 100% 100%;
    }
    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .avatar {
        width: 157px;
        height: 157px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name-tips {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 32px 0;
        box-sizing: border-box;
        .name {
          font-size: 40px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
        .tips {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #b4b4b4;
        }
      }
    }
    .right {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .action-item {
        width: 265px;
        height: 80px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        border: 3px solid rgba(41, 221, 205, 1);
        border-radius: 50px;
        &:first-child {
          margin-right: 38px;
        }
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
        &.active {
          background: rgba(40, 200, 188, 1);
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    left: unset;
    margin-left: unset;
    margin-right: unset;
    right: -240px;
    top: 50%;
    margin-top: -298px;
    .container {
      transform: scale(2);
    }
  }
}
</style>
