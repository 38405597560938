<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <!-- <div class="icon-tips"></div> -->
          <span>活动邀请链接</span>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <share-content :show="true" :isPop="true"></share-content>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ShareContent from "@/components/common/ShareContent.vue";
export default {
  components: {
    ShareContent,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/screen_projection/resource/resource_bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 2067px;
    height: 1048px;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 126px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      box-sizing: border-box;
      padding: 0 40px 0 178px;
      &::after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        // padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        box-sizing: border-box;
        .icon-tips {
          width: 54px;
          height: 52px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/icon_resource.png);
          background-size: 100% 100%;
          margin-right: 24px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      padding: 140px 140px 0 140px;
      box-sizing: border-box;
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
