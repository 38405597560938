<template>
  <div class="index">
    <pop-login
      :show="popLoginShow"
      :projectcode="project_code"
      @close="popLoginClose"
      @loginsuccess="getInfo"
    ></pop-login>
    <pop-top @open="popLoginOpen" :black="true"></pop-top>
    <pop-password
      :show="popPasswordShow"
      @close="popPasswordClose"
      @success="popPasswordVerify"
    ></pop-password>
    <div class="content-box">
      <div class="content-bg">
        <div class="left">
          <div
            class="img-wrapper"
            :style="{
              background: `url(${project_detail.cover})`,
              backgroundSize: '100% 100%',
            }"
          >
            <img :src="project_detail.cover" v-if="project_detail.cover" />
          </div>
          <div class="detail">
            <div class="detail-title">
              <span>{{ project_detail.name }}</span>
              <span>{{ project_detail.status_remark }}</span>
            </div>
            <div class="detail-time">
              <div class="time-item begin" v-if="project_detail.start_time">
                <span>{{ project_detail.start_time.substring(11, 16) }}</span>
                <span>{{
                  project_detail.start_time
                    .substring(0, 10)
                    .replace("-", "年")
                    .replace("-", "月") + "日"
                }}</span>
              </div>
              <div class="time-line"></div>
              <div class="time-item end" v-if="project_detail.end_time">
                <span>{{ project_detail.end_time.substring(11, 16) }}</span>
                <span>{{
                  project_detail.end_time
                    .substring(0, 10)
                    .replace("-", "年")
                    .replace("-", "月") + "日"
                }}</span>
              </div>
            </div>
            <div
              class="action cursor-pointer"
              v-if="$utils.checkWxOrMini(0)"
              @click.stop.prevent="goGame"
            >
              <span>进入活动</span>
            </div>
            <div
              class="action cursor-pointer"
              v-if="$utils.checkWxOrMini(1) && weappPathFinished"
            >
              <!-- <WxOpenLaunchWeapp :path="weappPath">
                <img :src="weappImgUrl" :style="weappStyle" />
              </WxOpenLaunchWeapp> -->
              <WxOpenLaunchWeapp :path="weappPath">
                <div :style="weappStyle.div" @click="autoLoginCheck">
                  <span :style="weappStyle.span">进入活动</span>
                </div>
              </WxOpenLaunchWeapp>
            </div>
          </div>
        </div>
        <div class="right">
          <share-content :show="shareContentShow"></share-content>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopLogin from "@/components/PopLogin.vue";
import PopPassword from "@/components/PopPassword.vue";
import PopTop from "@/components/PopTop.vue";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
import ShareContent from "@/components/common/ShareContent.vue";

export default {
  components: {
    PopLogin,
    PopTop,
    PopPassword,
    WxOpenLaunchWeapp,
    ShareContent,
  },
  data() {
    return {
      href: location.href,
      popLoginShow: false,
      popPasswordShow: false,
      project_code: "",
      projectVerifyPassword: false,
      weappPathFinished: false,
      project_detail: {
        name: "", // 标题
        no: "", // 活动号
        remark: "", // 右侧备注第一行
        start_time: "", // 开始时间 yyyy-mm-dd hh:mm:ss
        end_time: "", // 结束时间 yyyy-mm-dd hh:mm:ss
        status_remark: "", // 状态标题
        status: "", // 状态 2：进行中
        title: "", // 活动主题
        cover: "", // 封面图
        need_password: "", // 活动需要密码
      },
      weappImgUrl: this.$ossRes("/detail/action_in.png"),
      // weappStyle: {
      //   width: `${document.body.clientWidth - 35}px`,
      //   // height: "50px",
      //   // background: `url(${this.$ossRes("/detail/action_in.png")})`,
      //   // backgroundSize: "100% 100%",
      //   margin: "0 auto",
      //   marginTop: "60px",
      // },
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "0",
          transition: "all 0.3s ease-in-out",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "18px",
          fontFamily: "Source Han Sans CN-Normal, Source Han Sans CN",
          fontWeight: "400",
          color: "#ffffff",
        },
      },
      shareContentShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    weappPath() {
      return `subpackage_login/game/game?id=${sessionStorage.getItem(
        "activity_id"
      )}${process.env.NODE_ENV == "development" ? "_1" : ""}`;
    },
  },
  created() {
    this.activityIdSet();
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: "正在加载活动中...",
    });
    this.getUserInfo();
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    activityIdSet() {
      if (this.$route.query && this.$route.query.id) {
        const activity_id = this.$route.query.id;
        if (activity_id) {
          sessionStorage.setItem("activity_id", activity_id);
        }
      }
    },
    popPasswordVerify() {
      this.projectVerifyPassword = true;
      this.popPasswordClose();
      this.goGame();
    },
    popPasswordOpen() {
      this.popPasswordShow = true;
    },
    popPasswordClose() {
      this.popPasswordShow = false;
    },
    logout() {
      this.$storage.clearToken();
      this.$store.dispatch("clearUserInfo", "");
      this.$store.dispatch("clearConfig", "");
      this.$toast.success("已退出登录");
    },
    goMe() {
      window.open("https://test.333f.com/haijiaohui-admin");
    },
    async getUserInfo() {
      if (this.$storage.getToken()) {
        const userInfoResult = await this.$http.get("/user/userinfo");
        if (userInfoResult.code == 200) {
          this.$store.dispatch("saveUserInfo", userInfoResult.data.user_info);
          this.$store.dispatch("saveConfig", userInfoResult.data.config);
          if (userInfoResult.data.user_info["is_black"] == 1) {
            // 封禁用户，禁止进入活动
            this.$toast.fail("账号被封禁，无法登入");
          }
          console.log(userInfoResult.data.user_info, "userInfo");
        } else {
          this.$store.dispatch("clearUserInfo");
          this.$store.dispatch("clearConfig", "");
        }
        this.getInfo();
      } else {
        this.getInfo();
      }
    },
    getInfo() {
      this.$http.get("/activity/detail").then((res) => {
        if (res.code == 200 && res.data) {
          this.$toast.clear();
          this.project_code = res.data.project_code;
          const link =
            location.href +
            ((location.href.indexOf("?") > 0 ? "&" : "?") + "share=1");
          this.project_detail["href"] = link;
          this.project_detail["name"] = res.data.name;
          this.project_detail["no"] = res.data.no;
          this.project_detail["remark"] = res.data.remark;
          this.project_detail["start_time"] = res.data.start_time;
          this.project_detail["end_time"] = res.data.end_time;
          this.project_detail["status_remark"] = res.data.status_remark;
          this.project_detail["status"] = res.data.status;
          this.project_detail["title"] = res.data.title;
          this.project_detail["cover"] = res.data.cover;
          this.project_detail["need_password"] = res.data.need_password;
          this.project_detail["access_rule"] = res.data.access_rule;
          this.$store.dispatch("saveDetail", this.project_detail);
          this.shareContentShow = true;
          sessionStorage.setItem("appLink", res.data.activity_unity_link);
          sessionStorage.setItem("appKey", res.data.activity_unity_app_key);
          if (!this.project_detail["need_password"]) {
            this.projectVerifyPassword = true;
          }
          document.title = this.project_detail["name"];
          sessionStorage.setItem("project_name", this.project_detail["name"]);
          wxShare({
            title: this.project_detail.title,
            link: link,
          });
          if (
            this.$utils.isMobile().wechat &&
            !sessionStorage.getItem("isWxMini")
          ) {
            localStorage.setItem("autoLogin", 1);
          }
          if (
            this.userInfo &&
            sessionStorage.getItem("isWxMini") == 1 &&
            this.$utils.getUrlParam("share") != 1 &&
            Number(localStorage.getItem("autoLogin") || 0) > 0
          ) {
            this.goGame();
            localStorage.setItem("autoLogin", 0);
          }
        } else {
          this.$toast.fail("加载失败：" + res.msg);
        }
        this.weappPathFinished = true;
      });
    },
    autoLoginCheck() {
      if (
        this.$utils.isMobile().wechat &&
        !sessionStorage.getItem("isWxMini")
      ) {
        localStorage.setItem("autoLogin", 1);
      }
    },
    goGame() {
      if (!this.userInfo) {
        this.popLoginOpen();
        return;
      }
      if (this.userInfo["is_black"] == 1) {
        // 封禁用户，禁止进入活动
        this.$toast.fail("账号被封禁，无法登入");
        return;
      }
      if (!this.project_detail["access_rule"]) {
        // 该活动仅指定用户可访问
        this.$toast.fail("该活动仅指定用户可访问");
        return;
      }
      if (this.project_detail["need_password"] && !this.projectVerifyPassword) {
        this.popPasswordOpen();
        return;
      }
      if (this.project_detail["status"] != 2) {
        this.$toast.fail(`活动${this.project_detail["status_remark"]}`);
        return;
      }
      this.$router.push({
        name: routesConvert(routesModuleData.game, true).name,
        query: { id: sessionStorage.getItem("activity_id") },
      });
    },
    popLoginClose() {
      this.popLoginShow = false;
    },
    popLoginOpen() {
      this.popLoginShow = true;
    },
    onCopy() {
      this.$toast.success("复制成功");
    },
    onError() {
      this.$toast.fail("复制失败");
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 100%;
  min-height: 2160px;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: url(#{$PublicEnv}/detail/detail_bg_pc.png);
  background-size: 100% 100%;
  position: relative;
  .content-box {
    width: 100%;
    height: 100%;
    position: relative;
    .content-bg {
      width: 100%;
      height: 100%;
      position: relative;
      .left {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 350px;
        left: 0;
        right: 0;
        .img-wrapper {
          flex-shrink: 0;
          width: 1064px;
          height: 692px;
          margin-right: 148px;
          position: relative;
          border-radius: 16px;
          &::after {
            content: "";
            position: absolute;
            width: 918px;
            height: 34px;
            // background: url(#{$PublicEnv}/detail/detail_cover_shadow.png);
            // background-size: 100% 100%;
            bottom: -15px;
            left: 50%;
            margin-left: -459px;
            position: absolute;
            background: inherit;
            border-radius: 0;
            background-position: bottom;
            filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 1)) blur(22px);
            z-index: 1;
          }
          img {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            top: 0;
            z-index: 2;
          }
        }
        .detail {
          width: 882px;
          min-height: 692px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: flex-start;
          .detail-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              &:first-child {
                font-size: 72px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #252727;
              }
              &:last-child {
                flex-shrink: 0;
                width: 206px;
                height: 62px;
                background: rgba(251, 88, 97, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 36px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #fb5861;
                border-radius: 38px;
              }
            }
          }
          .detail-time {
            // margin-top: 170px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time-line {
              flex: 1;
              width: 100%;
              height: 12px;
              box-sizing: border-box;
              background: url(#{$PublicEnv}/detail/detail_line.png);
              background-size: 100% 100%;
              margin: 0 14px;
              margin-bottom: 60px;
            }
            .time-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              &.begin {
                align-items: flex-start;
              }
              &.end {
                align-items: flex-end;
              }
              span {
                &:first-child {
                  font-size: 88px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: bold;
                  color: #333333;
                }
                &:last-child {
                  margin-top: 16px;
                  font-size: 40px;
                  font-family: Source Han Sans CN-Light, Source Han Sans CN;
                  font-weight: 300;
                  color: #666666;
                }
              }
            }
          }
        }
        .action {
          width: 882px;
          max-width: 100%;
          height: 124px;
          border-radius: 80px;
          background: linear-gradient(
            90deg,
            rgba(83, 0, 198, 1),
            rgba(68, 44, 246, 1),
            rgba(33, 175, 253, 1)
          );
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 0;
          position: relative;
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          &::after {
            content: "";
            position: absolute;
            width: 882px;
            height: 60px;
            background: url(#{$PublicEnv}/detail/action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -441px;
          }
          &.no-background {
            margin-top: 0;
            &::after {
              display: none;
            }
          }
        }
      }
      .right {
        position: absolute;
        bottom: 154px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .copy-link-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .copy-link {
            width: 1934px;
            height: 110px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            position: relative;
            padding-bottom: 50px;
            &::after {
              content: "";
              position: absolute;
              border: 1px dashed rgba(211, 203, 203, 0.6);
              left: 0;
              bottom: 0;
              right: 40px;
              height: 0;
            }
            span {
              font-size: 28px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
        .copy-content-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 60px;
        }
        .copy-content {
          width: 1934px;
          box-sizing: border-box;
          position: relative;
          padding-bottom: 50px;
          &::after {
            content: "";
            position: absolute;
            border: 1px dashed rgba(211, 203, 203, 0.6);
            left: 0;
            bottom: 0;
            right: 40px;
            height: 0;
          }
          p {
            font-size: 32px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.9);
          }
        }
        .copy-link-action,
        .copy-content-action {
          width: 250px;
          height: 66px;
          background: rgba(60, 113, 255, 0.3);
          border: 2px solid rgba(60, 113, 255, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          margin-left: 178px;
          span {
            font-size: 28px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(132, 165, 255, 1);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .index {
    display: block;
    overflow-y: auto;
    background: rgba(250, 250, 252, 1);
    .content-box {
      background: none;
      padding: 0;
      .content-bg {
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(250, 250, 252, 1);
        .left {
          position: relative;
          height: 5801px;
          top: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-top: 599px;
          box-sizing: border-box;
          .img-wrapper {
            width: 3400px;
            height: 2253px;
            margin-right: 0;
            position: relative;
            border-radius: 41px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 41px;
            }
            &::after {
              width: 2933px;
              height: 109px;
              margin-left: -1466.5px;
              left: 50%;
              bottom: -50px;
              filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 1)) blur(66px);
            }
          }
          .detail {
            width: 100%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            margin-top: 450px;
            padding: 0 256px;
            .detail-title {
              span {
                &:first-child {
                  font-size: 174.08px;
                }
                &:last-child {
                  width: 680.96px;
                  height: 179.2px;
                  border-radius: 97.28px;
                  font-size: 102.4px;
                }
              }
            }
            .detail-time {
              margin-top: 194.56px;
              .time-line {
                margin: 0 180px;
                margin-bottom: 180px;
                height: 31px;
              }
              .time-item {
                span {
                  &:first-child {
                    font-size: 250.88px;
                  }
                  &:last-child {
                    margin-top: 46.08px;
                    font-size: 117.76px;
                  }
                }
              }
            }
          }
          .action {
            width: 3420px;
            height: 420px;
            margin-top: 420px;
            line-height: 420px;
            border-radius: 205px;
            span {
              font-size: 195px;
            }
          }
        }
        .right {
          position: relative;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 4808px;
          background: url(#{$PublicEnv}/detail/detail_bg_bottom_mobile.png);
          background-size: 100% 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-top: 502px;
          .copy-link-wrapper {
            flex-direction: column;
            align-items: center;
            position: relative;
            .copy-link {
              width: 3164px;
              height: auto;
              padding-bottom: 845px;
              span {
                font-size: 123px;
                color: #ffffff;
              }
            }
          }
          .copy-content-wrapper {
            flex-direction: column;
            align-items: center;
            position: relative;
            margin-top: 312px;
            .copy-content {
              width: 3164px;
              height: auto;
              padding-bottom: 845px;
              p {
                font-size: 123px;
                color: #ffffff;
              }
            }
          }
          .copy-link-action,
          .copy-content-action {
            width: auto;
            padding: 0 125px;
            height: 307px;
            position: absolute;
            right: 379px;
            bottom: 205px;
            span {
              font-size: 143px;
            }
          }
        }
      }
    }
  }
}
</style>
