<template>
  <div class="index">
    <div class="playbox"></div>
    <!-- <div class="player" id="local-player"></div> -->
    <pop-video-invite
      :show="popVideoInviteShow"
      :from_user="popVideoInviteUser"
      @close="popVideoInviteClose"
      @submit="popVideoInviteSubmit"
    ></pop-video-invite>
    <pop-share :show="shareShow" @close="shareClose"></pop-share>
    <pop-image
      :show="popImageShow"
      :url="popImageUrl"
      :text="popImageText"
      @close="popImageClose"
    ></pop-image>
    <pop-announcement
      :show="popAnnouncementShow"
      @send="u3dSendMessage"
      @close="popAnnouncementClose"
    ></pop-announcement>
    <pop-screen-projection-controller
      :show="popScreenProjectionControllerShow"
      :index="popScreenProjectionControllerIndex"
      @close="popScreenProjectionControllerClose"
      @screenclose="screenProjectionLookupScreenClose"
      @frontorback="screenProjectionCameraFrontOrBack"
      @reload="popScreenProjectionControllerReload"
    ></pop-screen-projection-controller>
    <pop-screen-projection-viewer
      :show="popScreenProjectionViewerShow"
      :agoraInfo="popScreenProjectionViewerAgoraInfo"
      :screenU3d="popScreenProjectionViewerAgoraScreenU3d"
      @close="popScreenProjectionViewerClose"
    ></pop-screen-projection-viewer>
    <pop-screen-projection-lookup
      :show="popScreenProjectionLookupShow"
      @close="popScreenProjectionLookupClose"
      @reload="popScreenProjectionLookupReload"
      @screenOpen="screenProjectionZoomIn"
      @screenclose="screenProjectionLookupScreenClose"
      @frontorback="screenProjectionCameraFrontOrBack"
    ></pop-screen-projection-lookup>
    <pop-screen-projection-resource
      :show="popScreenProjectionResourceShow"
      @close="popScreenProjectionResourceClose"
      @submit="popScreenProjectionResourceSubmit"
    ></pop-screen-projection-resource>
    <pop-video-chat
      ref="popVideoChat"
      :show="popVideoChatShow"
      @confirm="popConfirmOpen"
      @send="u3dSendMessage"
      @close="popVideoChatClose"
    ></pop-video-chat>
    <pop-screen-projection-confirm
      :show="popScreenProjectionConfirmShow"
      @submit="popScreenProjectionConfirmSubmit"
      @close="popScreenProjectionConfirmClose"
    ></pop-screen-projection-confirm>
    <pop-confirm
      :show="popConfirmShow"
      :tips="popConfirmTips"
      :user="popConfirmUser"
      @submit="popConfirmSubmit"
      @close="popConfirmClose"
    ></pop-confirm>
    <pop-card
      :show="popCardShow"
      :popCardShowIsNormal="popCardShowIsNormal"
      @send="u3dSendMessage"
      @confirm="popConfirmOpen"
      @close="popCardClose"
      @success="getUserInfo"
      :tab="popCardTab"
    ></pop-card>
    <pop-role-select
      ref="popRoleSelect"
      :show="popRoleSelectShow"
      :fromme="popRoleSelectFromMine"
      @close="popRoleSelectClose"
      @submit="popRoleSelectSubmit"
      @card="popCardOpen"
    ></pop-role-select>
    <pop-loading :show="popLoadingShow"></pop-loading>
    <pop-user-list
      :show="popUserListShow"
      :users="popUserList"
      :showadmin="popUserShowAdmin"
      :isaudioopening="isAudioOpening"
      @send="u3dSendMessage"
      @chat="popTextChatOpen"
      @video="popVideoChatOpen"
      @close="popUserListClose"
      @confirm="popConfirmOpen"
    ></pop-user-list>
    <pop-text-chat
      :show="popTextChatShow"
      :info="popTextChatInfo"
      @send="u3dSendMessage"
      @close="popTextChatClose"
    ></pop-text-chat>
    <canvas
      class="prepare-canvas"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_canvas_${i.screen.screen_u3d}`"
    ></canvas>
    <div
      class="prepare-div"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_div_${i.screen.screen_u3d}`"
    ></div>
    <video
      class="prepare-div"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_video_${i.screen.screen_u3d}`"
      webkit-playsinline="webkit-playsinline"
      playsinline="playsinline"
      x5-playsinline="true"
    ></video>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert } from "@/router/index";
import PopLoading from "@/components/PopLoading.vue";
import PopRoleSelect from "@/components/PopRoleSelect.vue";
import PopTextChat from "@/components/PopTextChat.vue";
import PopUserList from "@/components/PopUserList.vue";
import PopCard from "@/components/PopCard.vue";
import PopConfirm from "@/components/PopConfirm.vue";
import PopVideoChat from "@/components/PopVideoChat.vue";
import PopVideoInvite from "@/components/PopVideoInvite.vue";
import PopScreenProjectionConfirm from "@/components/PopScreenProjectionConfirm.vue";
import PopScreenProjectionLookup from "@/components/PopScreenProjectionLookup.vue";
import PopScreenProjectionController from "@/components/PopScreenProjectionController.vue";
import PopScreenProjectionViewer from "@/components/PopScreenProjectionViewer.vue";
import PopAnnouncement from "@/components/PopAnnouncement.vue";
import PopScreenProjectionResource from "@/components/PopScreenProjectionResource.vue";
import PopShare from "@/components/PopShare.vue";
import PopImage from "@/components/PopImage.vue";
export default {
  components: {
    PopUserList,
    PopTextChat,
    PopRoleSelect,
    PopLoading,
    PopCard,
    PopConfirm,
    PopVideoChat,
    PopVideoInvite,
    PopScreenProjectionConfirm,
    PopScreenProjectionLookup,
    PopScreenProjectionController,
    PopScreenProjectionViewer,
    PopAnnouncement,
    PopScreenProjectionResource,
    PopShare,
    PopImage,
  },
  data() {
    return {
      popUserListShow: false, // 用户管理列表
      popUserShowAdmin: false, // 是否展示管理员界面
      popUserList: [],

      popTextChatShow: false, // 文字聊天窗口
      popTextChatInfo: {},

      popRoleSelectShow: true, // 角色选择
      popRoleSelectFromMine: 0, // 是否来源于我的
      popLoadingShow: true, // 加载中

      popCardShow: false, // 名片
      popCardShowIsNormal: false, // 是否显示我的名片
      popCardTab: 0,

      popConfirmShow: false, // 提示
      popConfirmTips: "", // 提示内容
      popConfirmUser: {}, // 提示用户
      popConfirmSubmitCallback: {}, // 提示确认回调

      popVideoChatShow: false, // 视频聊天窗口

      popVideoInviteShow: false, // 视频聊天邀请窗口
      popVideoInviteUser: {}, // 视频聊天邀请来源用户
      popVideoInviteSubmitCallback: {}, // 视频聊天邀请确认回调函数

      popScreenProjectionConfirmShow: false, // 选择分享内容
      popScreenProjectionConfirmSubmitCallback: {}, // 选择分享内容确认回调函数

      popScreenProjectionResourceShow: false, // 投屏资源选择开关
      popScreenProjectionResourceScreenU3D: "", // 准备投屏资源的屏幕id

      popScreenProjectionLookupShow: false, // 查看正在分享内容

      popScreenProjectionControllerShow: false, // 查看投屏大屏控制器

      popScreenProjectionViewerShow: false, // 查看投屏大屏

      popAnnouncementShow: false, // 全域公告弹窗

      shareShow: false, // 分享弹窗

      isConnected: false,

      callbackWaitForConnect: "",
      isUserInfoConnect: false,

      isTest: !!sessionStorage.getItem("test_id"),
      ws: {},

      isAudioOpening: false,

      popImageShow: false,
      popImageUrl: "",
      popImageText: "",

      logoutAlready: false, // 是否已经退出
      prepareCurrentType: "",
      prepareCurrentIndex: -1,
    };
  },
  created() {
    window.addEventListener("resize", this.windowResize);
    this.websocketInit();
    this.$utils.fullscreen();
    if (
      !sessionStorage.getItem("appLink") ||
      !sessionStorage.getItem("appKey") ||
      !this.userInfo ||
      !this.userInfo.user_id ||
      !this.config
    ) {
      this.$toast("请重新登录...");
      this.$utils.exitFullscreen();
      let route = {
        name: routesConvert(routesModuleData.index, true).name,
      };
      if (this.$utils.getUrlParam("id")) {
        route = {
          name: routesConvert(routesModuleData.detail, true).name,
          query: { id: this.$utils.getUrlParam("id") },
        };
      }
      this.$router.replace(route);
      return;
    }
    const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
    const list = [];
    currentScene.screens.map((item) => {
      list.push({
        screen: item,
      });
    });
    this.$store.dispatch("savePrepareList", list);
    location.href.indexOf("localhost") < 0 &&
      window.history.replaceState({}, "", process.env.VUE_APP_SUB_URL + "/");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
    this.agoraRtc.client && this.agoraRtc.client.leave();
    this.agoraLive.client && this.agoraLive.client.leave();
    try {
      this.logoutAlready = true;
      this.$u3dLauncher.destory();
    } catch (e) {
      console.log(e, "断开连接失败");
    }
    this.screenVideoStopAll();
    this.$store.dispatch("saveDoingVideoProjectList", []);
    this.$store.dispatch("saveVideoChatUserList", []);
    this.$store.dispatch("savePrepareList", []);
    this.$store.dispatch("saveAgoraLiveListener", {});
    this.$store.dispatch(
      "saveAgoraLiveUserPublishedListenerMoreScreenBindList",
      []
    );
    this.$store.dispatch("saveAgoraLiveMoreList", []);
    this.$store.dispatch("saveAgoraLiveMoreScreenBindList", []);
  },
  mounted() {
    if (!this.isTest) {
      this.start();
    }
    window.workOpen = this.workOpen;
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["detail"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraRtc"]),
    ...mapGetters(["videoChatUserList"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
    ...mapGetters(["doingVideoProjectList"]),
    ...mapGetters(["agoraLiveUserPublishedListenerMoreScreenBindList"]),
    ...mapGetters(["prepareList"]),
    ...mapGetters(["agoraLiveListener"]),
    ...mapGetters(["onlineUserList"]),
  },
  watch: {
    "$store.state.agoraLiveMoreScreenBindList": {
      handler: function (v) {
        if (!v || !v.length) {
          this.u3dSendMessage({
            cmd_type: "screen_projection_clear",
            cmd_info: {},
          });
        }
      },
    },
  },
  methods: {
    popImageOpen({ img, text }) {
      this.popImageUrl = img;
      this.popImageText = text;
      this.popImageShow = true;
    },
    popImageClose() {
      this.popImageShow = false;
      this.popImageUrl = "";
    },
    workOpen({ value }) {
      switch (value) {
        case 0:
          // 客服企业微信二维码
          this.popImageOpen({
            img: this.$ossRes("/bskj/qrcode_qywx.jpg"),
            text: "长按识别二维码联系客服",
          });
          break;
        case 1:
          // 访问博士评测链接（企业科创属性数字化测评）
          this.popImageOpen({
            img: this.$ossRes("/bskj/qrcode_pc.png"),
            text: "长按识别二维码进入测评",
          });
          break;
        case 2:
          // 路演厅入口
          this.popConfirmOpen({
            tips: `是否确认进入路演厅？`,
            user: {},
            callback: () => {
              location.href = "https://meta.idea888.com/stage/detail?id=69";
            },
          });
          break;
      }
    },
    receiveFromUser({ from_user, info_json }) {
      const info = JSON.parse(info_json);
      const list = [];
      switch (info.info_type) {
        case "video_chat_invite_cancel":
          // 对方取消了您的聊天邀请
          this.videoChatUserList.map((i) => {
            if (i.user_id != from_user.user_id) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveVideoChatUserList", list);
          break;
        case "video_chat_invite_already":
          this.$toast.fail("您正在跟对方聊天...");
          break;
        case "video_chat_invite_busy":
          if (this.videoChatUserList && this.videoChatUserList.length == 2) {
            this.videoChatUserList.map((i) => {
              if (i.user_id != from_user.user_id) {
                list.push(i);
              }
            });
            this.$store.dispatch("saveVideoChatUserList", list);
            this.$refs.popVideoChat.$emit("close");
          }
          this.$toast.fail("对方正在忙线...");
      }
    },
    sendToUserMessage({ to_user, info_json }) {
      return this.u3dSendMessage({
        cmd_type: "send_to_user",
        cmd_info: {
          to_user,
          info_json,
        },
      });
    },
    async getUserInfo() {
      const userInfoResult = await this.$http.get("/user/userinfo");
      if (userInfoResult.code == 200) {
        this.$store.dispatch("saveUserInfo", userInfoResult.data.user_info);
        this.$store.dispatch("saveConfig", userInfoResult.data.config);
      }
    },
    popScreenProjectionViewerOpen({ screen_u3d, agora_info }) {
      this.popScreenProjectionViewerAgoraInfo = agora_info;
      this.popScreenProjectionViewerAgoraScreenU3d = screen_u3d;
      this.popScreenProjectionViewerShow = true;
    },
    popScreenProjectionViewerClose(info) {
      this.popScreenProjectionViewerShow = false;
      this.u3dSendMessage({
        cmd_type: "screen_projection_zoom_in_close",
        cmd_info: info,
      });
    },
    screenProjectionZoomIn({ screen_u3d }) {
      // 判断是否自己投屏
      let isMe = false,
        prepareIndex = -1,
        prepareOriginIndex = -1;
      this.prepareList.map((i, k) => {
        if (i.screen.screen_u3d == screen_u3d) {
          if (i.elem) {
            isMe = true;
            prepareIndex = k;
          }
          prepareOriginIndex = k;
        }
      });
      this.list = this.agoraLiveMoreScreenBindList;
      let liveIndex = -1;
      this.list.map((i, k) => {
        if (
          i.screen_u3d == this.prepareList[prepareOriginIndex].screen.screen_u3d
        ) {
          liveIndex = k;
          console.log(this.list[liveIndex], "this.list[liveIndex]");
        }
      });
      console.log(
        this.prepareList,
        "this.prepareList",
        isMe,
        prepareIndex,
        prepareOriginIndex
      );
      if (
        (isMe && prepareIndex > -1) ||
        (liveIndex > -1 &&
          (this.list[liveIndex].isCamera || this.list[liveIndex].isScreen))
      ) {
        this.popScreenProjectionControllerOpen(
          prepareIndex > -1 ? prepareIndex : prepareOriginIndex
        );
      } else {
        const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
        let uid = "";
        currentScene.screens.map((item) => {
          if (item.screen_u3d == screen_u3d) {
            uid = item.screen_u3d_uid;
          }
        });
        this.popScreenProjectionViewerOpen({
          screen_u3d,
          agora_info: {
            uid: uid,
            appId: currentScene.appId,
            channel: currentScene.channel,
          },
        });
      }
    },
    shareOpen() {
      this.shareShow = true;
    },
    shareClose() {
      this.shareShow = false;
    },
    async screenProjectionCameraFrontOrBack({ screen_u3d, value, callback }) {
      const _this = this;
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在切换摄像头...",
      });
      try {
        let index = -1,
          uid = "";
        const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
        this.agoraLiveMoreScreenBindList.map((i, k) => {
          if (i.screen_u3d == screen_u3d) {
            index = k;
            uid = i.uid;
          }
        });
        let mode = "";
        if (value) {
          // 后置
          mode = "environment";
        } else {
          // 前置
          mode = "user";
        }
        if (this.agoraLiveMoreList[index].localVideoTrack) {
          await this.agoraLiveMoreList[index].client.unpublish(
            this.agoraLiveMoreList[index].localVideoTrack
          );
          await this.agoraLiveMoreList[index].localVideoTrack.setEnabled(false);
          this.agoraLiveMoreList[index].localVideoTrack = undefined;
          this.agoraLiveMoreList[index].client.leave();
        }
        await _this.$agoraUtils.joinLiveMore({
          appId: currentScene.appId,
          channel: currentScene.channel,
          notCreate: true,
          uid: uid,
          callback: async (rtc) => {
            _this.agoraLiveMoreList[index].client = rtc.client;
            _this.agoraLiveMoreList[index].client.setClientRole(
              _this.$agoraUtils.ROLE_HOST
            ); // 切换主播
            setTimeout(async () => {
              _this.agoraLiveMoreList[index].localVideoTrack =
                await this.$agoraUtils.getAgoraRTC.createCameraVideoTrack({
                  facingMode: mode,
                  encoderConfig: this.$utils.isMobile().mobile
                    ? "480p_1"
                    : "1080p_1",
                });
              _this.agoraLiveMoreList[index].localVideoTrack.on(
                "track-ended",
                () => {
                  _this.screenProjectionClose({ screen_u3d });
                }
              );
              await _this.agoraLiveMoreList[index].client.publish(
                this.agoraLiveMoreList[index].localVideoTrack
              );
              callback && callback();
            }, 300);
          },
        });
        this.$toast.clear();
      } catch (e) {
        this.$toast.clear();
        this.$toast.fail("摄像头切换失败...");
      }
    },
    screenProjectionFileSuccess({ screen_u3d, agora_info }) {
      const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
      let screen_name = "";
      currentScene.screens.map((item) => {
        if (item.screen_u3d == screen_u3d) {
          screen_name = item.screen_name;
        }
      });
      this.$store.dispatch("addAgoraLiveMoreList", [
        {
          appId: agora_info.appId,
          channel: agora_info.channel,
          localAudioTrack: undefined, // 本地音频流
          localVideoTrack: undefined, // 本地视频流
          client: undefined, // 实例
          uid: agora_info.uid,
        },
      ]);
      this.$store.dispatch("addAgoraLiveMoreScreenBindList", [
        {
          screen_u3d: screen_u3d,
          screen_name: screen_name,
          uid: agora_info.uid,
          isCamera: false,
          isFile: true,
          liveIndex: -1,
        },
      ]);
    },
    popScreenProjectionResourceClose() {
      this.popScreenProjectionResourceShow = false;
    },
    popScreenProjectionResourceOpen() {
      this.popScreenProjectionResourceShow = true;
    },
    popScreenProjectionResourceSubmit(e) {
      this.screenProjectionAction({
        screen_u3d: this.popScreenProjectionResourceScreenU3D,
        type: 4,
        chooseItem: e,
      });
    },
    popScreenProjectionResourceSubmitOld(e) {
      console.log(`文件发起投屏：`, e);
      const _this = this;
      let isAlready = false; // 目前unity仅支持投单个文件
      this.agoraLiveMoreScreenBindList.map((i) => {
        if (i.isFile) {
          isAlready = true;
        }
      });
      const submit = () => {
        _this
          .u3dSendMessage({
            cmd_type: "screen_projection_file",
            cmd_info: {
              screen_u3d: _this.popScreenProjectionResourceScreenU3D,
              file_url: e.url,
              file_type: e.ext,
            },
          })
          .then((res) => {
            if (res) {
              _this.$toast.loading("正在投屏中...");
              _this.popScreenProjectionResourceClose();
            }
          });
      };
      const currentScene = _this.config.scenes[_this.userInfo.curr_scene_index];
      if (isAlready) {
        _this.popConfirmOpen({
          tips: `资源库不可同时投多个屏幕，点击继续将关闭其他投屏`,
          user: {},
          callback: () => {
            _this.agoraLiveMoreScreenBindList.map((i) => {
              if (i.isFile) {
                _this.screenProjectionClose({
                  screen_u3d: i.screen_u3d,
                  isMe: true,
                  isUnityPush: true,
                });
                _this.u3dSendMessage({
                  cmd_type: "screen_projection_stop",
                  cmd_info: {
                    screen_u3d: i.screen_u3d,
                    agora_info: {
                      appId: currentScene.appId,
                      channel: currentScene.channel,
                      uid: i.uid,
                    },
                  },
                });
              }
            });
            setTimeout(submit, 2000);
          },
        });
      } else {
        submit();
      }
    },
    screenProjectionReceiveAdmins({ from_user, screen_u3d, agora_info }) {
      let isExist = false;
      this.doingVideoProjectList.map((i) => {
        if (i.user.user_id == from_user.user_id) {
          isExist = true;
        }
      });
      if (isExist) {
        return;
      }
      this.$store.dispatch("addDoingVideoProjectList", [
        {
          user: from_user,
          screen_u3d: screen_u3d,
          agora_info: agora_info,
        },
      ]);
      // console.log(this.doingVideoProjectList, "doingVideoProjectList");
    },
    logoutByU3d({ way }) {
      if (this.logoutAlready) {
        return;
      }
      this.logoutAlready = true;
      const _this = this;
      _this.$toast.fail(way ? "您已重复登录" : "您已退出");
      this.$utils.exitFullscreen();
      setTimeout(() => {
        _this.$router.back(0);
      }, 300);
    },
    connectClose({ from_user }) {
      const _this = this;
      // 自己掉线
      if (!from_user || from_user.user_id == this.userInfo.user_id) {
        if (this.logoutAlready) {
          return;
        }
        this.logoutAlready = true;
        this.$toast("连接已断开，请重新登录...");
        this.$utils.exitFullscreen();
        setTimeout(() => {
          _this.$router.back(0);
        }, 300);
        return;
      }
      // 其他玩家掉线
      const list = [];
      // 检测视频
      this.videoChatUserList.length > 0 &&
        this.videoChatUserList.map((i) => {
          if (i.user_id != from_user.user_id) {
            list.push(i);
          }
        }) &&
        this.$store.dispatch("saveVideoChatUserList", list);
    },
    popAnnouncementOpen() {
      this.popAnnouncementShow = true;
    },
    popAnnouncementClose() {
      this.popAnnouncementShow = false;
    },
    videoChatOpen({ to_user }) {
      const _this = this;
      let isExist = false;
      if (this.videoChatUserList && this.videoChatUserList.length > 0) {
        this.videoChatUserList.map((i) => {
          if (i.user_id == to_user.user_id) {
            isExist = true;
          }
        });
      }
      if (isExist) {
        return;
      }
      if (_this.agoraRtc.client) {
        // 已加入视频聊天
        // 发送指令
        _this.u3dSendMessage({
          cmd_type: "video_chat_invite",
          cmd_info: {
            to_user: to_user,
            curr_user_list: this.videoChatUserList,
            agora_info: {
              appId: _this.agoraRtc.appId,
              channel: _this.agoraRtc.channel,
              uid: _this.agoraRtc.uid + "",
            },
          },
        });
        this.$store.dispatch("saveVideoChatUserList", [
          ...this.videoChatUserList,
          to_user,
        ]);
      } else {
        // 未加入视频聊天
        // 加入频道
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在创建聊天房间...",
        });
        const appId =
          _this.config.appIds[
            Math.floor(Math.random() * _this.config.appIds.length)
          ];
        const channel = `private_${_this.userInfo.user_id}_${
          to_user.user_id
        }_${Date.now()}`;
        _this.$agoraUtils.joinRtc({
          appId: appId,
          channel: channel,
          callback: async (rtc) => {
            // 将uid加入到userInfo
            const userInfo = _this.userInfo;
            userInfo["web_data"] = JSON.stringify({
              rtc_uid: rtc.uid,
              is_owner: 1,
              small_img: userInfo.small_img,
            });
            const result = await this.$http.post("/web-operate/create", {
              room_id: channel,
              detail: JSON.stringify({
                appId: appId,
              }),
            });
            if (result.code == 200) {
              // 弹出视频聊天窗口
              _this.popVideoChatOpen();
              // 发送指令
              _this.u3dSendMessage({
                cmd_type: "video_chat_invite",
                cmd_info: {
                  to_user: to_user,
                  curr_user_list: [userInfo],
                  agora_info: {
                    appId: appId,
                    channel: channel,
                    uid: rtc.uid + "",
                  },
                },
              });
              this.$store.dispatch("saveVideoChatUserList", [
                userInfo,
                to_user,
              ]);
              this.$toast.clear();
            } else {
              // 房间创建失败
              rtc.client.leave();
            }
          },
        });
      }
    },
    async screenVideoStopAll() {
      // 被中断投屏，被管理员取消授权投屏
      // 存在多个屏幕，逐一退出
      if (this.agoraLiveMoreList.length > 0) {
        this.agoraLiveMoreList.map((i, k) => {
          this.agoraLiveMoreScreenBindList[k];
          try {
            i.localVideoTrack._mediaStreamTrack &&
              i.localVideoTrack._mediaStreamTrack.stop();
          } catch (e) {
            console.log(e, "localVideoTrack._mediaStreamTrack");
          }
          if (i.localAudioTrack) {
            try {
              i.localAudioTrack._mediaStreamTrack &&
                i.localAudioTrack._mediaStreamTrack.stop();
            } catch (e) {
              console.log(e, "localAudioTrack._mediaStreamTrack");
            }
          }
          i.client.leave();
        });
        // this.agoraLiveMoreList = [];
        this.$store.dispatch("saveAgoraLiveMoreList", []);
      }
      // 关系List清除
      if (this.agoraLiveMoreScreenBindList.length > 0) {
        // this.agoraLiveMoreScreenBindList = [];
        this.$store.dispatch("saveAgoraLiveMoreScreenBindList", []);
      }
      this.popScreenProjectionControllerClose();
      this.popScreenProjectionViewerClose();
      // 基础client取消发布视频轨
      // if (this.agoraLive.localVideoTrack) {
      //   this.agoraLive.client.unpublish(
      //     this.agoraLive.localVideoTrack
      //   );
      //   await this.agoraLive.localVideoTrack.setEnabled(false);
      //   this.agoraLive.localVideoTrack = undefined;
      // }
    },
    screenProjectionLookupScreenClose(info) {
      const _this = this;
      _this.popConfirmOpen({
        tips: `是否结束该投屏？`,
        user: {},
        callback: () => {
          _this.screenProjectionClose(info);
        },
      });
    },
    async screenProjectionClose({ screen_u3d, isMe, isUnityPush, callback }) {
      let index = -2,
        listIndex = -1;
      this.agoraLiveMoreScreenBindList.map((i, k) => {
        if (i.screen_u3d == screen_u3d) {
          index = k; // i.liveIndex;
          listIndex = k;
        }
      });
      // console.log(index, screen_u3d, "screenProjectionClose");
      if (index > -2) {
        // if (index == -1) {
        //   if (this.agoraLive.localVideoTrack) {
        //     await this.agoraLive.client.unpublish(
        //       this.agoraLive.localVideoTrack
        //     );
        //     await this.agoraLive.localVideoTrack.setEnabled(
        //       false
        //     );
        //     this.agoraLive.localVideoTrack = undefined;
        //   }
        // } else {
        if (!isUnityPush) {
          if (this.agoraLiveMoreList[index].localVideoTrack) {
            await this.agoraLiveMoreList[index].client.unpublish(
              this.agoraLiveMoreList[index].localVideoTrack
            );
            await this.agoraLiveMoreList[index].localVideoTrack.setEnabled(
              false
            );
            try {
              this.agoraLiveMoreList[index].localVideoTrack._mediaStreamTrack &&
                this.agoraLiveMoreList[
                  index
                ].localVideoTrack._mediaStreamTrack.stop();
            } catch (e) {
              console.log(e, "localVideoTrack._mediaStreamTrack");
            }
            this.agoraLiveMoreList[index].localVideoTrack = undefined;
            if (this.agoraLiveMoreList[index].localAudioTrack) {
              await this.agoraLiveMoreList[index].client.unpublish(
                this.agoraLiveMoreList[index].localAudioTrack
              );
              await this.agoraLiveMoreList[index].localAudioTrack.setEnabled(
                false
              );
              try {
                this.agoraLiveMoreList[index].localAudioTrack
                  ._mediaStreamTrack &&
                  this.agoraLiveMoreList[
                    index
                  ].localAudioTrack._mediaStreamTrack.stop();
              } catch (e) {
                console.log(e, "localAudioTrack._mediaStreamTrack");
              }
              this.agoraLiveMoreList[index].localAudioTrack = undefined;
            }
            this.agoraLiveMoreList[index].client.leave();
            const tempList = [];
            this.agoraLiveMoreList.map((i, k) => {
              if (k != index) {
                tempList.push(i);
              }
            });
            // console.log(this.agoraLiveMoreList, "this.agoraLiveMoreList");
            // console.log(tempList, "this.agoraLiveMoreList");
            this.$store.dispatch("saveAgoraLiveMoreList", tempList);
          }
          // }
          const list = [];
          this.agoraLiveMoreScreenBindList.map((i, k) => {
            if (k != listIndex) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreScreenBindList", list);
        } else {
          const tempList = [];
          this.agoraLiveMoreList.map((i, k) => {
            if (k != index) {
              tempList.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreList", tempList);
          const list = [];
          this.agoraLiveMoreScreenBindList.map((i, k) => {
            if (k != listIndex) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreScreenBindList", list);
        }
        callback && callback();
      }
      try {
        const elem = document.getElementById(`prepare_video_${screen_u3d}`);
        elem.pause();
        elem.muted = true;
      } catch (e) {
        console.log(e, "e");
      }
      if (!isMe) {
        this.$toast("管理员已结束您的屏幕共享...");
      }
    },
    async screenProjectionAction({ screen_u3d, type, chooseItem }) {
      // 获取视频轨
      const _this = this;
      const isFullscreen = _this.$utils.isFullscreen();
      let videoTrack = {};
      let fileDocument = "",
        prepareObject = {};
      if (type == 3) {
        this.popScreenProjectionResourceScreenU3D = screen_u3d;
        this.popScreenProjectionResourceOpen();
        return;
      } else if (type == 1) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在共享屏幕中...",
        });
        try {
          // 共享屏幕
          videoTrack =
            await this.$agoraUtils.getAgoraRTC.createScreenVideoTrack(
              {
                encoderConfig: "1080p_1",
              },
              "auto"
            );
          this.$toast.clear();
        } catch (e) {
          this.$toast.clear();
          this.$toast.fail("共享屏幕失败...");
          return;
        }
      } else if (type == 2) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在开启摄像头...",
        });
        try {
          // 摄像头
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCameraVideoTrack({
              encoderConfig: _this.$utils.isMobile().mobile
                ? "480p_1"
                : "1080p_1",
            });
          this.$toast.clear();
        } catch (e) {
          this.$toast.clear();
          if (this.$utils.isMobile().iPhone) {
            this.$toast.fail("请更新iOS版本至14.3以上...");
          } else {
            this.$toast.fail("请先接入摄像头...");
          }
          return;
        }
      } else if (type == 4) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在投屏中...",
        });
        console.log(chooseItem, "chooseItem");
        if (["mp4", "mov"].includes(chooseItem.ext)) {
          // 视频
          fileDocument = `prepare_video_${screen_u3d}`;
          const { stream, elem } = await this.$utils.getVideoStream({
            elem: document.getElementById(fileDocument),
            url: chooseItem.url,
          });
          prepareObject.url = chooseItem.url;
          prepareObject.elem = elem;
          videoTrack = [];
          videoTrack[0] =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getVideoTracks()[0],
            });
          videoTrack[1] =
            await _this.$agoraUtils.getAgoraRTC.createCustomAudioTrack({
              mediaStreamTrack: stream.getAudioTracks()[0],
            });
        } else if (["jpg", "jpeg", "png"].includes(chooseItem.ext)) {
          // 图片
          fileDocument = `prepare_canvas_${screen_u3d}`;
          const { stream, elem, img } = await this.$utils.getCanvasStream({
            elem: document.getElementById(fileDocument),
            url: chooseItem.url,
          });
          prepareObject.url = chooseItem.url;
          prepareObject.elem = elem;
          prepareObject.img = img;
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getTracks()[0],
            });
        } else if (["pdf"].includes(chooseItem.ext)) {
          // PDF
          fileDocument = `prepare_canvas_${screen_u3d}`;
          // const { stream, elem, img, pdfNumPages } =
          //   await this.$utils.getCanvasStreamFromPDF({
          //     elem: document.getElementById(fileDocument),
          //     url: chooseItem.url,
          //   });
          const { stream, elem, img } = await this.$utils.getCanvasStream({
            elem: document.getElementById(fileDocument),
            url: chooseItem.pdf2imgs[0].url,
            fillColor: "white",
          });
          prepareObject.url = chooseItem.pdf2imgs[0].url;
          prepareObject.elem = elem;
          prepareObject.img = img;
          prepareObject.pdf2imgs = chooseItem.pdf2imgs;
          prepareObject.pdfNumPages = chooseItem.pdf2imgs.length;
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getTracks()[0],
            });
        }
      }
      // 若只投1个屏幕，则使用场景的视频轨。
      // 若投多个屏幕，则需要创建新的实例去获取新的uid。
      // 因为一个uid实例，只能投一路视频轨。
      const currentScene = _this.config.scenes[_this.userInfo.curr_scene_index];
      let uid = "";
      let screen_name = "";
      currentScene.screens.map((item) => {
        if (item.screen_u3d == screen_u3d) {
          screen_name = item.screen_name;
          uid = item.screen_u3d_uid;
        }
      });
      console.log(uid, "投屏-1");
      const uidBindSendMsg = ({
        i,
        k,
        isCamera,
        isFile,
        fileExt,
        isScreen,
      }) => {
        _this.$store.dispatch("addAgoraLiveMoreScreenBindList", [
          {
            screen_u3d: screen_u3d,
            screen_name: screen_name,
            uid: i,
            isCamera: isCamera,
            isScreen: isScreen,
            liveIndex: k,
            isFile: isFile,
            fileExt: fileExt,
          },
        ]);
        _this.u3dSendMessage({
          cmd_type: "screen_projection_receive",
          cmd_info: {
            screen_u3d: screen_u3d,
            is_camera: isCamera ? 1 : 0,
            is_file: isFile ? 1 : 0,
            file_ext: fileExt,
            agora_info: {
              appId: currentScene.appId,
              channel: currentScene.channel,
              uid: i + "",
            },
          },
        });
        _this.popScreenProjectionResourceClose();
      };
      try {
        // 第N个屏幕
        await _this.$agoraUtils.joinLiveMore({
          appId: currentScene.appId,
          channel: currentScene.channel,
          uid: uid,
          callback: async (rtc) => {
            // uid = rtc.uid;
            console.log(rtc.uid, "投屏-2");
            const index = _this.agoraLiveMoreList.length - 1;
            uidBindSendMsg({
              i: uid,
              k: index,
              isScreen: !!(type == 1),
              isCamera: !!(type == 2),
              isFile: !!(type == 4),
              fileExt: type == 4 ? chooseItem.ext : "",
            });
            _this.agoraLiveMoreList[index].client.setClientRole(
              _this.$agoraUtils.ROLE_HOST
            ); // 切换主播
            setTimeout(async () => {
              if (Array.isArray(videoTrack)) {
                _this.agoraLiveMoreList[index].localVideoTrack = videoTrack[0];
                _this.agoraLiveMoreList[index].localAudioTrack = videoTrack[1];
                _this.agoraLiveMoreList[index].localVideoTrack.on(
                  "track-ended",
                  () => {
                    _this.screenProjectionClose({ screen_u3d });
                  }
                );
                await _this.agoraLiveMoreList[index].client.publish([
                  _this.agoraLiveMoreList[index].localAudioTrack,
                  _this.agoraLiveMoreList[index].localVideoTrack,
                ]);
              } else {
                _this.agoraLiveMoreList[index].localVideoTrack = videoTrack;
                _this.agoraLiveMoreList[index].localVideoTrack.on(
                  "track-ended",
                  () => {
                    _this.screenProjectionClose({ screen_u3d });
                  }
                );
                await _this.agoraLiveMoreList[index].client.publish(
                  _this.agoraLiveMoreList[index].localVideoTrack
                );
                // _this.agoraLiveMoreList[index].localVideoTrack.play(
                //   `prepare_video_${screen_u3d}`
                // );
                _this.internalVideoTrackPlay(index, screen_u3d);
              }
              const list = [];
              let prepareIndex = -1;
              this.prepareList.map((i, k) => {
                let info = i;
                if (i.screen.screen_u3d == screen_u3d) {
                  info = {
                    ...info,
                    ...prepareObject,
                    liveIndex: index,
                  };
                  prepareIndex = k;
                }
                console.log(info, "info");
                list.push(info);
              });
              _this.$store.dispatch("savePrepareList", list);
              _this.$nextTick(() => {
                _this.popScreenProjectionControllerOpen(prepareIndex);
              });
            }, 300);
            if (isFullscreen) {
              _this.$utils.fullscreen();
            }
          },
        });
      } catch (e) {
        console.log(e, "共享屏幕失败-2");
      }
      // }
    },
    internalVideoTrackPlay(index, screen_u3d) {
      if (this.agoraLiveMoreList[index].localVideoTrack) {
        this.agoraLiveMoreList[index].localVideoTrack.play(
          `prepare_video_${screen_u3d}`
        );
        // setTimeout(() => {
        //   this.internalVideoTrackPlay(index, screen_u3d);
        // }, 1000);
      }
    },
    async screenProjectionListener({ screen_u3d }) {
      const _this = this;
      _this.popScreenProjectionConfirmOpen({
        callback: async (type) => {
          _this.screenProjectionAction({
            screen_u3d,
            type,
          });
        },
      });
    },
    videoChatOwnerChangeReceiveListener() {
      const _this = this;
      const list = _this.videoChatUserList;
      console.log(list, "videoChatOwnerChangeReceiveListener");
      list.map((i, k) => {
        if (i.user_id == _this.userInfo.user_id) {
          if (list[k]["web_data"]) {
            const webData = JSON.parse(list[k]["web_data"]);
            webData.is_owner = 1;
            list[k]["web_data"] = JSON.stringify(webData);
          } else {
            list[k]["web_data"] = JSON.stringify({
              is_owner: 1,
            });
          }
        }
      });
      console.log(list, "videoChatOwnerChangeReceiveListener");
      _this.$store.dispatch("saveVideoChatUserList", list);
    },
    videoChatJoinBackListener({ from_user, agora_info }) {
      let exist = false;
      const _this = this;
      const list = _this.videoChatUserList;
      list.map((i, k) => {
        if (i.user_id == from_user.user_id) {
          exist = true;
          list[k]["web_data"] = JSON.stringify({
            rtc_uid: agora_info.uid + "",
          });
        }
      });
      if (!exist) {
        from_user["web_data"] = JSON.stringify({
          rtc_uid: agora_info.uid + "",
        });
        list.push(from_user);
      }
      _this.$store.dispatch("saveVideoChatUserList", list);
    },
    async audioCloseListener() {
      // 关闭麦克风
      if (this.agoraLive.client && this.agoraLive.localAudioTrack) {
        await this.agoraLive.client.unpublish(this.agoraLive.localAudioTrack);
        await this.agoraLive.localAudioTrack.setEnabled(false);
        this.agoraLive.localAudioTrack = undefined;
      }
      this.isAudioOpening = false;
    },
    async audioOpenListener() {
      const _this = this;
      // 校验是否正在视频聊天，若是，则拒绝
      if (this.popVideoChatShow) {
        _this.$toast.fail("不能同时发起发言与视频聊天");
        return;
      }
      // 校验是否有权限
      if (!_this.userInfo.is_audio) {
        _this.$toast.fail("没有发言权限");
        return;
      }
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在开启麦克风...",
      });
      const currentScene = _this.config.scenes[_this.userInfo.curr_scene_index];
      // console.log(
      //   _this.agoraLive,
      //   "_this.agoraLive"
      // );
      try {
        _this.$agoraUtils.setLiveClientRoleHost(); // 切换主播
        _this.agoraLive.localAudioTrack =
          await _this.$agoraUtils.getAgoraRTC.createMicrophoneAudioTrack();
        await _this.agoraLive.client.publish(_this.agoraLive.localAudioTrack);
        // 发送指令
        _this.u3dSendMessage({
          cmd_type: "audio_open_success",
          cmd_info: {
            agora_info: {
              appId: currentScene.appId,
              channel: currentScene.channel,
              uid: _this.agoraLive.uid + "",
            },
          },
        });
        _this.isAudioOpening = true;
      } catch (e) {
        console.log(e, "audioOpenListener-fail");
        this.$toast.clear();
        if (this.$utils.isMobile().iPhone) {
          this.$toast.fail("请更新iOS版本至14.3以上...");
        } else {
          this.$toast.fail("请先接入麦克风...");
        }
      }
      this.$toast.clear();
    },
    popVideoChatOpen() {
      this.popVideoChatShow = true;
    },
    async popVideoInviteOpen({ from_user, curr_user_list, agora_info }) {
      const _this = this;
      // 校验房间是否存在
      const roomResult = await this.$http.get("/web-operate/detail", {
        room_id: agora_info.channel,
      });
      if (roomResult.code != 200) {
        // 房间已销毁
        this.$toast.fail("对方已取消聊天邀请");
        return;
      }
      if (_this.agoraRtc.client) {
        // 已加入视频聊天
        let alreadyChatting = false;
        this.videoChatUserList.map((i) => {
          if (i.user_id == from_user.user_id) {
            alreadyChatting = true;
          }
        });
        if (alreadyChatting) {
          // 对方正在跟你聊天，但又发起了邀请
          this.sendToUserMessage({
            to_user: from_user,
            info_json: JSON.stringify({
              info_type: "video_chat_invite_already",
            }),
          });
        } else {
          // 对方是陌生人
          this.sendToUserMessage({
            to_user: from_user,
            info_json: JSON.stringify({
              info_type: "video_chat_invite_busy",
            }),
          });
        }
        return;
      }
      if (_this.popVideoInviteShow) {
        // 有人已对您发起聊天邀请
        this.sendToUserMessage({
          to_user: from_user,
          info_json: JSON.stringify({
            info_type: "video_chat_invite_busy",
          }),
        });
        return;
      }
      _this.popVideoInviteUser = {};
      _this.popVideoInviteSubmitCallback = {};
      _this.popVideoInviteUser = from_user;
      _this.popVideoInviteSubmitCallback = async () => {
        _this.$toast.loading("加载中...");
        // 校验房间是否存在
        const roomResult = await this.$http.get("/web-operate/detail", {
          room_id: agora_info.channel,
        });
        if (roomResult.code != 200) {
          // 房间已销毁
          _this.$toast.fail("对方已取消聊天邀请");
          return;
        }
        if (_this.isAudioOpening) {
          _this.$toast.fail("不能同时发起发言与视频聊天");
          return;
        }
        // alreadyExist && _this.popVideoChatClose();
        _this.audioCloseListener();
        _this.$nextTick(() => {
          const json = agora_info;
          const uid = _this.$agoraUtils.getPrivateUidByUserId(
            _this.userInfo.user_id
          );
          _this.$agoraUtils.joinRtc({
            appId: json.appId,
            channel: json.channel,
            uid: uid,
            callback: async () => {
              // 添加用户列表
              const userInfo = _this.userInfo;
              userInfo["web_data"] = JSON.stringify({
                rtc_uid: _this.agoraRtc.uid + "",
              });
              _this.$store.dispatch("addVideoChatUserList", [
                userInfo,
                ...curr_user_list,
              ]);
              // 加入后需要发送指令uid回传到房间内用户的客户端
              _this.u3dSendMessage({
                cmd_type: "video_chat_receive_open_confirm",
                cmd_info: {
                  curr_user_list: curr_user_list,
                  agora_info: {
                    appId: json.appId,
                    channel: json.channel,
                    uid: _this.agoraRtc.uid + "",
                  },
                },
              });
              _this.$toast.clear();
              _this.popVideoInviteClose();
              _this.popVideoChatOpen();
            },
          });
        });
      };
      _this.popVideoInviteShow = true;
    },
    popVideoInviteClose(to_user) {
      if (to_user) {
        this.sendToUserMessage({
          to_user,
          info_json: JSON.stringify({
            info_type: "video_chat_invite_cancel",
          }),
        });
      }
      this.popVideoInviteShow = false;
    },
    popVideoInviteSubmit() {
      this.popVideoInviteSubmitCallback && this.popVideoInviteSubmitCallback();
      this.popVideoInviteClose();
    },
    popVideoChatClose() {
      this.popVideoChatShow = false;
      if (this.agoraRtc.client) {
        this.agoraRtc.client.leave();
        this.agoraRtc.client = undefined;
      }
      this.$store.dispatch("saveVideoChatUserList", []);
    },
    popConfirmOpen({ tips, user, callback }) {
      // const _this = this;
      this.popConfirmTips = tips;
      this.popConfirmUser = user;
      this.popConfirmSubmitCallback = () => {
        // _this.audioCloseListener();
        callback && callback();
      };
      this.popConfirmShow = true;
    },
    popConfirmSubmit() {
      this.popConfirmSubmitCallback && this.popConfirmSubmitCallback();
      this.popConfirmClose();
    },
    popConfirmClose() {
      this.popConfirmTips = "";
      this.popConfirmUser = {};
      this.popConfirmSubmitCallback = {};
      this.popConfirmShow = false;
    },
    popScreenProjectionControllerOpen(index) {
      this.popScreenProjectionControllerIndex = index;
      this.popScreenProjectionControllerShow = true;
    },
    popScreenProjectionControllerClose() {
      this.popScreenProjectionControllerShow = false;
    },
    popScreenProjectionLookupOpen() {
      this.popScreenProjectionLookupShow = true;
    },
    popScreenProjectionLookupClose() {
      this.popScreenProjectionLookupShow = false;
    },
    popScreenProjectionControllerReload(e) {
      this.popScreenProjectionControllerClose();
      setTimeout(() => {
        this.popScreenProjectionControllerOpen(e);
      }, 100);
    },
    popScreenProjectionLookupReload() {
      this.popScreenProjectionLookupClose();
      setTimeout(() => {
        this.popScreenProjectionLookupOpen();
      }, 100);
    },
    popScreenProjectionConfirmOpen({ callback }) {
      this.popScreenProjectionConfirmSubmitCallback = callback;
      this.popScreenProjectionConfirmShow = true;
    },
    popScreenProjectionConfirmSubmit(data) {
      this.popScreenProjectionConfirmSubmitCallback &&
        this.popScreenProjectionConfirmSubmitCallback(data);
      this.popScreenProjectionConfirmClose();
    },
    popScreenProjectionConfirmClose() {
      this.popScreenProjectionConfirmSubmitCallback = {};
      this.popScreenProjectionConfirmShow = false;
    },
    popCardOpen(isNormal) {
      // this.allPopClose();
      this.popCardShowIsNormal = !!isNormal;
      this.popCardShow = true;
    },
    popCardClose() {
      this.popCardShow = false;
    },
    allPopClose() {
      this.popTextChatClose();
      this.popUserListClose();
      this.popCardClose();
    },
    popLoadingClose() {
      this.popLoadingShow = false;
    },
    popRoleSelectClose() {
      this.popRoleSelectShow = false;
      this.popRoleSelectFromMine = 0;
    },
    async agoraLiveUserUnPublishedListener(user) {
      const obj = this.agoraLiveListener;
      if (
        this.popScreenProjectionViewerShow &&
        this.popScreenProjectionViewerAgoraInfo.uid == user.uid
      ) {
        this.popScreenProjectionViewerClose();
      }
      if (
        this.popScreenProjectionControllerShow &&
        this.prepareList[this.popScreenProjectionControllerIndex]
          .screen_u3d_uid == user.uid
      ) {
        this.popScreenProjectionControllerClose();
      }
      if (user.uid in obj) {
        obj[user.uid] = {};
      }
      this.$store.dispatch("saveAgoraLiveListener", obj);
      console.log(this.agoraLiveListener, "agoraLiveUserUnPublishedListener");
    },
    async agoraLiveUserPublishedListener(user, mediaType) {
      const obj = this.agoraLiveListener;
      if (!(user.uid in obj)) {
        obj[user.uid] = {};
      }
      obj[user.uid].uid = user.uid;
      obj[user.uid][mediaType] = user;
      this.$store.dispatch("saveAgoraLiveListener", obj);
      console.log(this.agoraLiveListener, "agoraLiveUserPublishedListener");
      // 判断是否有投屏资源库 -- unity推流
      setTimeout(() => {
        this.checkUserFromAgoraLiveUserPublishedListener(user, mediaType);
      }, 2000);
    },
    checkUserFromAgoraLiveUserPublishedListener(user, mediaType) {
      this.list = this.agoraLiveMoreScreenBindList;
      if (this.list.length > 0) {
        this.list.map(async (i, k) => {
          if (mediaType !== "audio" && i.isFile && user.uid == i.uid) {
            await this.agoraLive.client.subscribe(user, mediaType);
            const temp =
              this.agoraLiveUserPublishedListenerMoreScreenBindList || [];
            temp[k] = {
              videoTrack: user.videoTrack,
              uid: user.uid,
              k: k,
              i: i,
            };
            this.$store.dispatch(
              "saveAgoraLiveUserPublishedListenerMoreScreenBindList",
              temp
            );
          }
        });
      }
    },
    async autoJoinAgoraLive(callback) {
      const _this = this;
      // 握手成功、登录、同时进入场景频道
      // console.log(this.config, "this.config");
      // console.log(this.userInfo, "this.userInfo");
      const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
      await this.$agoraUtils.joinLive({
        appId: currentScene.appId,
        channel: currentScene.channel,
        callback: async () => {
          const userInfo = _this.userInfo;
          let webData = {};
          if (userInfo.web_data) {
            try {
              webData = JSON.parse(userInfo.web_data);
            } catch (e) {
              console.log(111);
            }
          }
          webData["live_uid"] = _this.agoraLive.uid;
          userInfo["web_data"] = JSON.stringify(webData);
          _this.$store.dispatch("saveUserInfo", userInfo);
          if (currentScene.user_type == 0) {
            // 若为普通用户，则设为观众
            _this.$agoraUtils.setLiveClientRoleAudience();
          }
          _this.agoraLive.client.on(
            "user-published",
            _this.agoraLiveUserPublishedListener
          );
          _this.agoraLive.client.on(
            "user-unpublished",
            _this.agoraLiveUserUnPublishedListener
          );
          callback && callback();
        },
      });
    },
    popRoleSelectSubmit() {
      const _this = this;
      if (_this.isConnected) {
        _this.autoJoinAgoraLive(() => {
          _this.u3dSendMessage({
            cmd_type: "login",
            config: _this.config,
            cmd_info: {},
          });
          _this.isUserInfoConnect = true;
        });
      } else {
        _this.callbackWaitForConnect = () => {
          return new Promise((resolve) => {
            if (!_this.isUserInfoConnect) {
              _this.autoJoinAgoraLive(() => {
                _this
                  .u3dSendMessage({
                    cmd_type: "login",
                    config: _this.config,
                    cmd_info: {},
                  })
                  .then((res) => {
                    resolve(res);
                  });
              });
            } else {
              resolve(false);
            }
          });
        };
      }
      this.popRoleSelectShow = false;
      this.popRoleSelectFromMine = 0;
    },
    textChatMsgListener({ chat_type, from_user, messages }) {
      // 若为私聊，则过滤重复加入到临时左侧用户列表
      chat_type == 1 &&
        from_user &&
        this.$store.dispatch("addTextChatUserList", from_user);
      this.$store.dispatch(
        `${chat_type == 1 ? "addMessage" : "addMessageAll"}`,
        {
          to_user: chat_type == 1 ? from_user : {},
          messages,
        }
      );
      // console.log(this.message, "message");
    },
    cardReceiveListener({ from_user }) {
      this.$http.post("/user/collectCard", {
        from_user_id: from_user.user_id,
      });
    },
    authAudioApplyListener({ from_user, value }) {
      if (value == 1) {
        // 添加发言申请
        from_user["submit_time"] = Date.now();
        from_user["submit_status"] = 0;
        this.$store.dispatch("addTalkAuthApplyList", from_user);
      } else if (value == 0) {
        // 取消发言申请
        this.$store.dispatch("cancelTalkAuthApplyList", from_user);
      }
    },
    authAudioReceiveListener({ value }) {
      const userInfo = this.userInfo;
      userInfo["is_audio"] = value;
      this.$store.dispatch("saveUserInfo", userInfo);
      if (!value) {
        this.audioCloseListener();
      }
    },
    authVideoReceiveListener({ value }) {
      const userInfo = this.userInfo;
      userInfo["is_video"] = value;
      this.$store.dispatch("saveUserInfo", userInfo);
      if (value == 0) {
        this.screenVideoStopAll();
      }
    },
    sceneChangeListener({ scene_u3d }) {
      const userInfo = this.userInfo;
      const config = this.config;
      let curr_scene_index = "",
        curr_scene_id = "";
      let is_audio = 0,
        is_video = 0;
      config.scenes.map((i, k) => {
        if (i.scene_u3d === scene_u3d) {
          curr_scene_index = k;
          curr_scene_id = i.scene_id;
          if (i.user_type == 1) {
            is_audio = 1;
            is_video = 1;
          }
        }
      });
      userInfo["curr_scene_id"] = curr_scene_id;
      userInfo["curr_scene_index"] = curr_scene_index;
      userInfo["is_audio"] = is_audio;
      userInfo["is_video"] = is_video;
      this.$store.dispatch("saveUserInfo", userInfo);
      this.u3dSendMessage({
        cmd_type: "login",
        config: this.config,
        cmd_info: {},
      });
      // 如果正在视频聊天，自动退出
      if (this.agoraRtc.client) {
        this.agoraRtc.client.leave();
        this.agoraRtc.client = undefined;
        // 发送指令
        if (this.videoChatUserList.length > 1 && this.isOwner) {
          this.u3dSendMessage({
            cmd_type: "video_chat_owner_change",
            cmd_info: {
              to_user: this.videoChatUserList[1],
            },
          });
        }
        this.$store.dispatch("saveVideoChatUserList", []);
        this.popVideoChatClose();
      }
      // 如果正在发言，自动退出
      if (this.isAudioOpening) {
        this.audioCloseListener();
      }
    },
    popTextChatOpen(textChatInfo) {
      // this.allPopClose();
      // 显示文字聊天窗口
      // 若为私聊，则过滤重复加入到临时左侧用户列表
      textChatInfo.chat_type == 1 &&
        textChatInfo.to_user &&
        this.$store.dispatch("addTextChatUserList", textChatInfo.to_user);
      this.popTextChatInfo = textChatInfo;
      this.popTextChatShow = true;
    },
    popTextChatClose() {
      this.popTextChatShow = false;
    },
    popUserListOpen({ user_list, value }) {
      this.popUserList = user_list || [];
      this.popUserShowAdmin = !!value;
      this.popUserListShow = true;
    },
    popUserListClose() {
      this.popUserListShow = false;
      this.popUserList = [];
    },
    websocketInit() {
      const _this = this;
      if (this.isTest) {
        if ("WebSocket" in window) {
          console.log("您的浏览器支持WebSocket");
          // this.ws = new WebSocket("ws://221.224.254.37:8099/u3dws"); //创建WebSocket连接
          this.ws = new WebSocket("ws://120.24.62.167:18308/echo"); //创建WebSocket连接
          //申请一个WebSocket对象，参数是服务端地址，同http协议使用http://开头一样，WebSocket协议的url使用ws://开头，另外安全的WebSocket协议使用wss://开头
          this.ws.onopen = function () {
            //当WebSocket创建成功时，触发onopen事件
            console.log("open");
            _this.u3dSendMessageForConnect();
            _this.u3dSendMessageForHeart();
          };
          this.ws.onmessage = function (e) {
            //当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
            if (
              e.data &&
              JSON.parse(e.data).test_id ==
                sessionStorage.getItem("test_id").replace("b", "a")
            ) {
              console.log(e.data, "onmessage");
              _this.u3dMessagesReceive(e.data);
            }
          };
          this.ws.onclose = function (e) {
            //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
            console.log(e, "onclose");
            console.log("close");
          };
          this.ws.onerror = function (e) {
            //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
            console.log(e, "onerror");
          };
        } else {
          console.log("您的浏览器不支持WebSocket");
        }
      }
    },
    resetTitle(t) {
      if (t < 15) {
        document.title = sessionStorage.getItem("project_name");
        setTimeout(() => {
          this.resetTitle(++t);
        }, 600);
      }
    },
    async start() {
      const _this = this;
      const nowTime = Date.now();
      console.log(`开始挂载时间：` + nowTime);
      try {
        const option = {
          baseOptions: {
            appKey: sessionStorage.getItem("appKey"),
            address:
              sessionStorage.getItem("appLink") || "https://app.3dcat.live",
            startType: 1,
          },
          extendOptions: {
            openWebRTCStats: true,
            onPlay: () => {
              //web端->应用
              const endTime = Date.now();
              console.log(`进入初始化时间：` + endTime);
              console.log(
                `云渲染挂载-初始化，加载耗时：${(endTime - nowTime) / 1000}s`
              );
              _this.u3dSendMessageForConnect();
              setTimeout(() => {
                // 显示模式，1：自适应，2：拉伸模式，3：裁剪模式
                _this.$u3dLivePlayer.handleChangeLandscapeType(1);
                _this.popLoadingClose();
              }, 2000);
            },
          },
        };
        if (sessionStorage.getItem("appLink") == "https://app.3dcat.live") {
          // 公有云
          _this.$u3dLauncher = new window.LiveCat.Launcher(option);
        } else {
          // 私有云
          _this.$u3dLauncher = new window.LiveCat.LauncherPrivate(option);
        }
        _this.resetTitle(0);
        await _this.$u3dLauncher.automata(document.querySelector(".playbox"));
        // 与云渲染交互
        _this.$u3dConnection = _this.$u3dLauncher.getConnection();
        // _this.$u3dConnection.changeBandwidth(20000);
        // 获取播放示例
        _this.$u3dLivePlayer = _this.$u3dLauncher.getPlayer();
        // 接收应用端返回数据
        _this.$u3dConnection.event.interaction.on(_this.u3dMessagesReceive);
        // 连接中断回调
        _this.$u3dConnection.event.close.on(() => {
          _this.u3dClose("网络异常，连接中断，请重新进入...");
        });
        // 信令断开回调
        _this.$u3dConnection.event.disconnect.on(() => {
          _this.u3dClose("网络不稳定，已断开连接，请重新进入...");
        });
        // 超时断开
        _this.$u3dConnection.event.afk.on(() => {
          _this.u3dClose("超时断开连接，请重新进入...");
        });
      } catch (e) {
        console.log("云渲染启动异常：", e, "start");
        _this.$toast(`活动太火爆了，请稍后再试哟:${e}`);
        setTimeout(() => {
          _this.$router.back(0);
        }, 3000);
      }
    },
    u3dClose(msg) {
      this.$toast(msg);
      setTimeout(() => {
        this.$router.back(0);
      }, 2500);
    },
    u3dSendMessageForHeart() {
      const _this = this;
      _this.u3dSendMessage({
        cmd_type: "connect_heart",
        cmd_info: {},
      });
      setTimeout(() => {
        _this.u3dSendMessageForHeart();
      }, 3000);
    },
    u3dSendMessageForConnect() {
      const _this = this;
      if (!_this.isConnected) {
        _this.u3dSendMessage({
          cmd_type: "connect_check",
          cmd_info: {},
        });
        setTimeout(() => {
          _this.u3dSendMessageForConnect();
        }, 1000);
      }
    },
    u3dSendMessageHeart() {
      const _this = this;
      _this.u3dSendMessage({
        cmd_type: "connect_check_heart",
        cmd_info: {},
      });
      setTimeout(() => {
        _this.u3dSendMessageHeart();
      }, 5000);
    },
    u3dSendMessage({ cmd_type, cmd_info, config }) {
      if (this.logoutAlready) {
        return;
      }
      const sendObject = {
        cmd_type: cmd_type || "",
        cmd_info: cmd_info || {},
        user_info: this.userInfo,
        cmd_time: Date.now() + "",
      };
      if (this.isTest) {
        sendObject["test_id"] = sessionStorage.getItem("test_id");
      }
      if (config) {
        sendObject.config = config;
      }
      if (this.isTest) {
        return new Promise((resolve) => {
          this.ws.send(JSON.stringify(sendObject));
          if (
            cmd_type == "connect_check" ||
            cmd_type == "connect_heart" ||
            cmd_type == "connect_check_heart"
          ) {
            console.log(`指令${cmd_type},发送成功,时间：${Date.now()}`);
          } else {
            console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
          }
          // cmd_type != "connect_check" &&
          //   console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
          resolve(true);
        });
      } else {
        return this.$u3dConnection
          .emitUIInteraction(JSON.stringify(sendObject))
          .then((res) => {
            if (
              cmd_type == "connect_check" ||
              cmd_type == "connect_heart" ||
              cmd_type == "connect_check_heart"
            ) {
              console.log(
                `指令${cmd_type},发送${
                  res ? "成功" : "失败"
                },时间：${Date.now()}`
              );
            } else {
              console.log(
                `${JSON.stringify(sendObject)},发送${res ? "成功" : "失败"}`
              );
            }
            return res;
          })
          .catch((e) => {
            console.log(e, "已断开连接");
          });
      }
    },
    cardSendU3d({ to_user }) {
      const _this = this;
      _this.popConfirmOpen({
        tips: `确定向[${to_user.user_name}]发送你的名片?`,
        user: {},
        callback: () => {
          _this.u3dSendMessage({
            cmd_type: "card_send",
            cmd_info: {
              to_user: to_user,
            },
          });
          _this.$toast.success("发送成功");
        },
      });
    },
    u3dMessagesReceive(msg) {
      if (msg) {
        let json = "";
        try {
          json = JSON.parse(msg);
        } catch (e) {
          console.log("指令格式错误");
        }
        if (json) {
          const { cmd_type, user_info, cmd_info } = json;
          console.log("收到指令：", json);
          if (cmd_type != "user_list_update") {
            // 在线用户列表更新-跳过过滤
            if (user_info.user_id != this.userInfo.user_id) {
              console.log("此指令非本人操作，过滤");
              return;
            }
          }
          switch (cmd_type) {
            case "connect_check":
              // 验证通信
              this.isConnected = true;
              console.log("通信握手成功...");
              this.u3dSendMessageHeart();
              this.callbackWaitForConnect &&
                this.callbackWaitForConnect() &&
                (this.isUserInfoConnect = true) &&
                (this.callbackWaitForConnect = "");
              break;
            case "text_chat_open":
              // 显示聊天窗口
              this.popTextChatOpen(cmd_info);
              break;
            case "text_chat_msg":
              // 获取新的聊天消息（场景：当前聊天，右侧聊天记录更新）
              this.textChatMsgListener(cmd_info);
              break;
            case "user_list_open":
              this.allPopClose();
              // 显示用户列表窗口
              this.popUserListOpen(cmd_info);
              break;
            case "card_open":
              // 显示我的名片
              this.popCardOpen(true);
              break;
            case "card_receive":
              // 收到名片
              this.cardReceiveListener(cmd_info);
              break;
            case "card_send_u3d":
              // 发送名片
              this.cardSendU3d(cmd_info);
              break;
            case "scene_change":
              // 切换场景
              this.sceneChangeListener(cmd_info);
              break;
            case "auth_audio_apply":
              // 发言授权（发送端用户申请）-接收端为管理员
              this.authAudioApplyListener(cmd_info);
              break;
            case "auth_audio_receive":
              // 发言授权（接收端用户收到管理员授权操作信息auth_audio，转发到h5）-接收端为普通用户
              this.authAudioReceiveListener(cmd_info);
              break;
            case "auth_audio_stop_receive":
              // 发言授权（接收端用户收到管理员中断操作信息auth_audio_stop，转发到h5）
              this.audioCloseListener(cmd_info);
              break;
            case "auth_video_receive":
              // 投屏授权（接收端用户收到管理员授权操作信息auth_video，转发到h5）
              this.authVideoReceiveListener(cmd_info);
              break;
            case "auth_video_stop_receive":
              // 投屏授权（接收端用户收到管理员中断操作信息auth_video_stop，转发到h5）
              this.screenVideoStopAll(cmd_info);
              break;
            case "add_black_receive":
              // 封禁（接收端用户收到管理员授权操作信息auth_video，转发到h5）-接收端为普通用户
              this.$toast.fail("您已被封禁...");
              setTimeout(() => {
                this.$router.back(0);
              }, 300);
              break;
            case "kickout_receive":
              // 踢出（接收端用户收到管理员授权操作信息kickout，转发到h5）-接收端为普通用户
              this.$toast.fail("您已被踢出...");
              setTimeout(() => {
                this.$router.back(0);
              }, 300);
              break;
            case "video_chat_receive_open":
              // 显示聊天窗口（u3d客户端的接收端收到聊天邀请，点击）
              this.popVideoInviteOpen(cmd_info);
              break;
            case "audio_open":
              // 准备发言
              this.audioOpenListener(cmd_info);
              break;
            case "audio_close":
              // 关闭发言
              this.audioCloseListener(cmd_info);
              break;
            case "video_chat_join_back":
              // 通知到客户端已加入视频聊天
              this.videoChatJoinBackListener(cmd_info);
              break;
            case "video_chat_owner_change_receive":
              // 收到转移群主操作
              this.videoChatOwnerChangeReceiveListener(cmd_info);
              break;
            case "screen_projection":
              // 准备投屏
              this.screenProjectionListener(cmd_info);
              break;
            case "screen_projection_lookup":
              // 投屏查看
              this.popScreenProjectionLookupOpen();
              break;
            case "screen_projection_close":
              // u3d点击屏幕，关闭屏幕投屏
              this.screenProjectionClose({
                ...cmd_info,
                isMe: true,
                isUnityPush: true,
              });
              break;
            case "video_chat_open":
              // 显示聊天窗口（u3d客户端的发送端点击别人）
              this.videoChatOpen(cmd_info);
              break;
            case "announcement_action":
              // 点击全域公告
              this.popAnnouncementOpen(cmd_info);
              break;
            case "mine":
              // 点击我的
              this.popRoleSelectShow = true;
              this.popRoleSelectFromMine = 1;
              break;
            case "fullscreen":
              // 全屏
              if (cmd_info.value) {
                this.$utils.fullscreen();
              } else {
                this.$utils.exitFullscreen();
              }
              break;
            case "connect_close":
              // 连接已断开
              this.connectClose(cmd_info);
              break;
            case "video_chat_kickout_receive":
              // 收到踢出房间video_chat_kickout
              this.popVideoChatClose();
              break;
            case "logout":
              // 退出游戏，0：主动退出，1：重复登录，被踢出。
              this.logoutByU3d(cmd_info);
              break;
            case "screen_projection_receive_admins":
              // 所有管理员端收到screen_projection_receive后，回传到h5，绑定user_id和声网uid
              this.screenProjectionReceiveAdmins(cmd_info);
              break;
            case "screen_projection_file_success":
              // 收到screen_projection_file指令后，投屏文件，投屏成功后回传到web
              this.screenProjectionFileSuccess(cmd_info);
              break;
            case "screen_projection_camera_front_or_back":
              // 投屏切换摄像头
              this.screenProjectionCameraFrontOrBack(cmd_info);
              break;
            case "shareOpen":
              // 打开分享弹窗
              this.shareOpen(cmd_info);
              break;
            case "screen_projection_zoom_in":
              // 打开分享弹窗
              this.screenProjectionZoomIn(cmd_info);
              break;
            case "sound_control":
              // 全局声音控制
              this.$store.dispatch("saveSoundControl", cmd_info.value);
              break;
            case "user_list_update":
              // 在线用户列表更新
              this.$store.dispatch("saveOnlineUserList", cmd_info.user_list);
              break;
            case "receive_from_user":
              // 收到来源客户端的发送指令
              this.receiveFromUser(cmd_info);
              break;
            case "work_open":
              // 特定业务弹窗
              this.workOpen(cmd_info);
              break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: black;
  .playbox {
    width: 100%;
    height: 100%;
  }
  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    height: 800px;
  }
  .prepare-canvas,
  .prepare-video,
  .prepare-div {
    position: fixed;
    top: 99999px;
    left: 99999px;
    width: 400px;
    height: 800px;
    z-index: 999;
  }
}
</style>
