var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{class:`copy-wrapper ${_vm.isPop ? 'is-pop' : ''}`},[_c('div',{staticClass:"copy-link-wrapper"},[_c('div',{staticClass:"copy-link"},[_c('span',[_vm._v(_vm._s(_vm.detail.href))])]),_c('div',{staticClass:"copy-link-action cursor-pointer"},[_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.detail.href),expression:"detail.href",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}]},[_vm._v("复制链接")])])]),_c('div',{staticClass:"copy-content-wrapper"},[_c('div',{staticClass:"copy-content"},[_c('p',[_vm._v(_vm._s(_vm.detail.remark))]),_c('p',[_vm._v("活动主题: "+_vm._s(_vm.detail.title))]),(_vm.detail.start_time && _vm.detail.end_time)?_c('p',[_vm._v(" 活动时间: "+_vm._s(_vm.detail.start_time.substring(0, 16).replace(/-/g, "/"))+"-"+_vm._s(_vm.detail.end_time.substring(0, 16).replace(/-/g, "/"))+" ")]):_vm._e(),_c('p',[_vm._v("活动号: "+_vm._s(_vm.detail.no))]),_c('p',[_vm._v("活动链接: "+_vm._s(_vm.detail.href))])]),_c('div',{staticClass:"copy-content-action cursor-pointer"},[_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
          `${_vm.detail.remark}
活动主题: ${_vm.detail.title}
活动时间: ${_vm.detail.start_time
  .substring(0, 16)
  .replace(/-/g, '/')}-${_vm.detail.end_time.substring(0, 16).replace(/-/g, '/')}
活动号: ${_vm.detail.no}
活动链接: ${_vm.detail.href}`
        ),expression:"\n          `${detail.remark}\n活动主题: ${detail.title}\n活动时间: ${detail.start_time\n  .substring(0, 16)\n  .replace(/-/g, '/')}-${detail.end_time.substring(0, 16).replace(/-/g, '/')}\n活动号: ${detail.no}\n活动链接: ${detail.href}`\n        ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}]},[_vm._v("复制内容和链接")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }