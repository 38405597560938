<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <span class="title">云渲染启动中...</span>
    <span class="loading">LOADING</span>
    <!-- <span class="tips"
      >使用W、A、S、D键可以行走，拖动鼠标左键可以调整您的视角</span
    > -->
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(#{$PublicEnv}/pop/loading/bg_v2.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    font-size: 96px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    line-height: 96px;
    letter-spacing: 40px;
    text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.302);
    background: linear-gradient(180deg, #ffffff 2%, #dde1fe 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none;
  }
  .loading {
    margin-top: 80px;
    font-size: 100px;
    font-family: Effort-Regular, Effort;
    font-weight: 400;
    opacity: 0.4;
    color: #ffffff;
    line-height: 100px;
    letter-spacing: 14px;
    text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
    user-select: none;
  }
  .tips {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 94px;
    font-size: 60px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 2px;
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .title {
      transform: scale(2);
    }
    .tips {
      transform: scale(2);
    }
  }
}
</style>
